import { MDBBtn, MDBCol, MDBInput, MDBRow } from "mdb-react-ui-kit";
import { useEffect, useState } from "react";
import { getAuth } from "firebase/auth";
import "./Notifications.scss";
import { CLOUDINARY_CONFIG, TINYMCE_API_KEY } from "./services/constants";
import { TemplateInterface } from "./services/interfaces";
import { Editor } from "@tinymce/tinymce-react";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { db } from "./services/firebase.config";
import { toast } from "react-toastify";

function Template({ userUid }: { userUid: string }) {
  const initialTemplate = {
    logo: "",
    imageCouverture: "",
    titre: "",
    footer: "",
    raisonSociale: "",
    cgv: "",
    remerciements: "",
    userUid,
  };

  const [template, setTemplate] = useState<TemplateInterface>(initialTemplate);

  const openWidget = (logo: boolean) => {
    // @ts-ignore
    const cloudinaryWidget = window.cloudinary.createUploadWidget(
      CLOUDINARY_CONFIG,
      // @ts-ignore
      (error, result) => {
        if (!error && result && result.event === "success") {
          console.log("Done! Here is the image info: ", result.info);

          if (logo) {
            setTemplate({ ...template, logo: result.info.secure_url });
          } else
            setTemplate({
              ...template,
              imageCouverture: result.info.secure_url,
            });
        }
      }
    );
    cloudinaryWidget.open();
  };

  useEffect(() => {
    const getTemplate = async () => {
      console.log("userUid :", userUid);
      const docRef = doc(db, "templates", userUid);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setTemplate(docSnap.data() as TemplateInterface);
      }
    };

    getTemplate();
  }, [userUid]);

  const submitTemplate = async (e: any) => {
    e.preventDefault();

    await setDoc(doc(db, "templates", userUid), template);
    window.scrollTo(0, 0);

    toast("Template sauvegardé", { type: "success" });
  };

  return (
    <MDBRow>
      <form onSubmit={submitTemplate}>
        <div className="bloc">
          <div className="row">
            <MDBCol className="col-md-12 col-12 mb-3">
              <MDBInput
                required
                value={template.titre}
                label="Titre du compte rendu"
                onChange={(e) =>
                  setTemplate({ ...template, titre: e.target.value })
                }
              />
            </MDBCol>
          </div>
        </div>

        <div className="bloc">
          <div className="row">
            <div className="col-12">
              <div className="text-center mt-3 mb-3">
                <MDBBtn
                  id="upload_widget"
                  onClick={(e) => {
                    e.preventDefault();
                    openWidget(true);
                  }}
                  color="primary"
                  size="lg"
                  rounded
                  outline
                >
                  Logo de la page d'accueil
                </MDBBtn>
              </div>
            </div>
          </div>
          <MDBRow>
            {template.logo.length > 0 && (
              <>
                <MDBCol lg="4" md="12" className="mb-4 mx-auto d-block">
                  <img
                    src={template.logo}
                    className="img-fluid img-thumbnail rounded"
                    alt=""
                  />
                </MDBCol>
                <div className="col-12">
                  <MDBBtn
                    color="danger"
                    onClick={() => {
                      if (window.confirm("Supprimer le logo ?")) {
                        setTemplate({ ...template, logo: "" });
                      }
                    }}
                    size="lg"
                    rounded
                    className="mx-auto d-block"
                  >
                    Supprimer
                  </MDBBtn>
                </div>
              </>
            )}
          </MDBRow>
        </div>
        <div className="bloc">
          <div className="row">
            <div className="col-12">
              <div className="text-center mt-3 mb-3">
                <MDBBtn
                  id="upload_widget"
                  onClick={(e) => {
                    e.preventDefault();
                    openWidget(false);
                  }}
                  color="primary"
                  size="lg"
                  rounded
                  outline
                >
                  Image de couverture
                </MDBBtn>
              </div>
            </div>
          </div>
          <MDBRow>
            {template.imageCouverture.length > 0 && (
              <>
                <MDBCol lg="4" md="12" className="mb-4 mx-auto d-block">
                  <img
                    src={template.imageCouverture}
                    className="img-fluid img-thumbnail rounded"
                    alt=""
                  />
                </MDBCol>
                <div className="col-12">
                  <MDBBtn
                    color="danger"
                    onClick={() => {
                      if (window.confirm("Supprimer l'image de couverture ?")) {
                        setTemplate({ ...template, imageCouverture: "" });
                      }
                    }}
                    size="lg"
                    rounded
                    className="mx-auto d-block"
                  >
                    Supprimer
                  </MDBBtn>
                </div>
              </>
            )}
          </MDBRow>
        </div>
        <div className="bloc">
          {" "}
          <div className="row">
            <div className="col-12">
              <h4 className="text-center mb-3">Décharge et responsabilité</h4>
              <Editor
                apiKey={TINYMCE_API_KEY}
                init={{
                  menubar: false,
                  plugins:
                    "anchor autolink charmap link lists searchreplace table visualblocks wordcount",
                  toolbar:
                    "forecolor backcolor | fontfamily fontsize | bold italic underline | link | align lineheight | numlist bullist",
                }}
                value={template.cgv}
                onEditorChange={(newValue) => {
                  setTemplate({ ...template, cgv: newValue });
                }}
              />
            </div>
          </div>
        </div>
        <div className="bloc">
          {" "}
          <div className="row">
            <div className="col-12">
              <h4 className="text-center mb-3">Raison sociale</h4>
              <Editor
                apiKey={TINYMCE_API_KEY}
                init={{
                  menubar: false,
                  plugins:
                    "anchor autolink charmap link lists searchreplace table visualblocks wordcount",
                  toolbar:
                    "forecolor backcolor | fontfamily fontsize | bold italic underline | link | align lineheight | numlist bullist",
                }}
                value={template.raisonSociale}
                onEditorChange={(newValue) => {
                  setTemplate({ ...template, raisonSociale: newValue });
                }}
              />
            </div>
          </div>
        </div>
        <div className="bloc">
          {" "}
          <div className="row">
            <div className="col-12">
              <h4 className="text-center mb-3">Informations en pied de page</h4>
              <Editor
                apiKey={TINYMCE_API_KEY}
                init={{
                  menubar: false,
                  plugins:
                    "anchor autolink charmap link lists searchreplace table visualblocks wordcount",
                  toolbar:
                    "forecolor backcolor | fontfamily fontsize | bold italic underline | link | align lineheight | numlist bullist",
                }}
                value={template.footer}
                onEditorChange={(newValue) => {
                  setTemplate({ ...template, footer: newValue });
                }}
              />
            </div>
          </div>
        </div>

        <div className="bloc mb-3 text-center position-sticky">
          <MDBBtn type="submit" color="primary" size="lg" rounded>
            Enregistrer
          </MDBBtn>
        </div>
      </form>
    </MDBRow>
  );
}

export default Template;
