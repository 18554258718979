import {
  MDBBtn,
  MDBRow,
  MDBSpinner,
  MDBCard,
  MDBCardBody,
  MDBCardFooter,
  MDBCardText,
  MDBCardTitle,
} from "mdb-react-ui-kit";
import "../Dashboard.scss";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { InterventionInterface } from "../services/interfaces";
import { query, collection, where, getDocs } from "firebase/firestore";
import { db } from "../services/firebase.config";
import { INTERVENTIONS } from "../services/constants";
import { renderDate } from "../services/common";

function Scan() {
  const [interventions, setInterventions] = useState<InterventionInterface[]>(
    []
  );

  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);

    const currentUrl = window.location.href;
    console.log("url : ", currentUrl);
    const getInterventions = async () => {
      const q = query(
        collection(db, "interventions"),
        where("QrCode", "==", currentUrl)
      );

      const querySnapshot = await getDocs(q);

      const result: InterventionInterface[] = [];
      querySnapshot.forEach((intervention) => {
        result.push({
          ...(intervention.data() as InterventionInterface),
          id: intervention.id,
        });
      });
      // @ts-ignore
      const sorted = result.sort((a, b) => b.date - a.date);

      setInterventions(sorted);
    };
    getInterventions();
    setIsLoading(false);
  }, []);

  if (isLoading) {
    return (
      <MDBSpinner className="mx-2" color="info">
        <span className="visually-hidden">Loading...</span>
      </MDBSpinner>
    );
  }

  return (
    <div className="container">
      <MDBRow>
        <div className="bloc">
          <h3 className="text-center">Liste des interventions</h3>
        </div>

        {interventions.map((intervention) => (
          <MDBCard>
            <MDBCardBody>
              <MDBCardTitle>{renderDate(intervention.date)} </MDBCardTitle>
              <MDBCardText>
                <div
                  className="custom-badge"
                  style={{
                    backgroundColor: INTERVENTIONS.find(
                      (item) => item.nom === intervention.nom
                    )?.couleur,
                  }}
                >
                  {intervention.nom}
                </div>
              </MDBCardText>
            </MDBCardBody>
            <MDBCardFooter className="text-muted">
              <Link to={"/compte-rendu/" + intervention.id} target="_blank">
                <MDBBtn outline rounded>
                  Voir le compte rendu
                </MDBBtn>
              </Link>
            </MDBCardFooter>
          </MDBCard>
        ))}
      </MDBRow>
    </div>
  );
}

export default Scan;
