import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCXheDR9_odAWaC22pSdtQio4Gs07oh6yI",
  authDomain: "poolcare-24731.firebaseapp.com",
  projectId: "poolcare-24731",
  storageBucket: "poolcare-24731.appspot.com",
  messagingSenderId: "503206775120",
  appId: "1:503206775120:web:1d079cc952428238e98455",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
