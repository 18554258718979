import {
  MDBBtn,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBSpinner,
  MDBCheckbox,
} from "mdb-react-ui-kit";
import "./Dashboard.scss";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { TechnicienInterface } from "./services/interfaces";
import { setDoc, doc, collection, addDoc, getDoc } from "firebase/firestore";
import { db } from "./services/firebase.config";
import { getAuth } from "firebase/auth";
import { Categories } from "./services/constants";

function Technicien({ userUid }: { userUid: string }) {
  const initialTechnicien = {
    nom: "",
    prenom: "",
    telephone: "",
    email: "",
    competences: [],
    userUid,
  };

  const { id } = useParams();

  const navigate = useNavigate();

  const optionsCompetences = Categories.map((competence) => competence.label);

  const [technicien, setTechnicien] =
    useState<TechnicienInterface>(initialTechnicien);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const techniciens = collection(db, "techniciens");

  const updateCompetences = (option: string) => {
    let competences = [];
    if (technicien.competences.some((competence) => competence === option)) {
      competences = technicien.competences.filter(
        (competence) => competence !== option
      );
    } else {
      competences = technicien.competences;
      competences.push(option);
    }

    setTechnicien({ ...technicien, competences });
  };

  useEffect(() => {
    const getTechnicien = async () => {
      if (id) {
        const docRef = doc(db, "techniciens", id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setTechnicien(docSnap.data() as TechnicienInterface);
        }
      }
    };

    if (id) {
      setIsLoading(true);
      getTechnicien();
      setIsLoading(false);
    }
  }, [id, userUid]);

  const submitTechnicien = async (e: any) => {
    setIsLoading(true);
    e.preventDefault();
    if (!id) {
      await addDoc(techniciens, technicien);
      navigate("/techniciens");
    } else {
      await setDoc(doc(db, "techniciens", id), technicien);
    }
    window.scrollTo(0, 0);

    setIsLoading(false);
  };

  return (
    <MDBRow>
      <div className="bloc">
        {isLoading ? (
          <MDBSpinner className="mx-2" color="info">
            <span className="visually-hidden">Loading...</span>
          </MDBSpinner>
        ) : (
          <>
            <form onSubmit={submitTechnicien}>
              <MDBRow className="mb-4">
                <MDBCol className="col-md-6 col-12 mb-3">
                  <MDBInput
                    required
                    value={technicien.nom}
                    label="Nom"
                    onChange={(e) =>
                      setTechnicien({ ...technicien, nom: e.target.value })
                    }
                  />
                </MDBCol>
                <MDBCol className="col-md-6 col-12">
                  <MDBInput
                    required
                    value={technicien.prenom}
                    label="Prénom"
                    onChange={(e) =>
                      setTechnicien({ ...technicien, prenom: e.target.value })
                    }
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow className="mb-4">
                <MDBCol className="col-md-6 col-12  mb-3">
                  <MDBInput
                    required
                    value={technicien.telephone}
                    label="Téléphone"
                    onChange={(e) =>
                      setTechnicien({
                        ...technicien,
                        telephone: e.target.value,
                      })
                    }
                  />
                </MDBCol>
                <MDBCol className="col-md-6 col-12">
                  <MDBInput
                    required
                    value={technicien.email}
                    label="Email"
                    onChange={(e) =>
                      setTechnicien({ ...technicien, email: e.target.value })
                    }
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow className="mb-4">
                <h4>Compétences</h4>
                <div className="w-100 mt-3">
                  {optionsCompetences.map((option, index) => {
                    return (
                      <MDBCheckbox
                        checked={technicien.competences.some(
                          (competence) => competence === option
                        )}
                        onClick={() => updateCompetences(option)}
                        key={index}
                        name={option}
                        value={option}
                        label={option}
                        inline
                      />
                    );
                  })}
                </div>
              </MDBRow>

              <div className="col-12 text-center">
                <MDBBtn type="submit" color="primary" size="lg" rounded>
                  Enregistrer
                </MDBBtn>
              </div>
            </form>
          </>
        )}
      </div>
    </MDBRow>
  );
}

export default Technicien;
