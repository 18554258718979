import {
  MDBTable,
  MDBTableHead,
  MDBTableBody,
  MDBBadge,
  MDBBtn,
  MDBRow,
} from "mdb-react-ui-kit";
import "./Dashboard.scss";
import { useState } from "react";
import { Link } from "react-router-dom";

function DetailIntervention() {
  const [historique, setHistorique] = useState([
    {
      date: "06/08/2023",
      type_intervention: "Rattrapage eau",
      technicien: "technicien 1",
    },
    {
      date: "01/02/2023",
      type_intervention: "Nettoyage",
      technicien: "technicien 2",
    },
    {
      date: "08/09/2022",
      type_intervention: "Nettoyage",
      technicien: "technicien 1",
    },
  ]);

  const dateFormated = new Date().toLocaleDateString("fr-FR");

  return (
    <MDBRow>
      <div className="bloc">
        <div className="row">
          <div className="col-9">
            <h3>Intervention du {dateFormated}</h3>
          </div>
          <div className="col-3">
            <h3>
              {" "}
              <MDBBadge color="primary" pill>
                Nettoyage
              </MDBBadge>
            </h3>
          </div>
        </div>
      </div>
      <div className="bloc">
        <div className="row">
          <h4>Détails de l'intervention</h4>
          <div className="col-9">
            <textarea className="form-control" rows={5}></textarea>
          </div>
          <div className="col-3">
            <Link to="/cloture-intervention">
              <MDBBtn className="w-100 mb-3" size="lg" color="success">
                Terminer l'intervention
              </MDBBtn>
            </Link>
            <MDBBtn className="w-100" color="info" size="lg">
              Reprogrammer l'intervention
            </MDBBtn>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-6 bloc">
          <h4>Information clients</h4>
          <p>Mr leroy Stéphane</p>
          <p>130 route de cugnaux</p>
          <p>31600 Seysses</p>
          <p>0836656565</p>
          <p>leroy.stephane@gmail.com</p>
        </div>
        <div className="col-6 bloc">
          <div className="row">
            <h4>Caractéristiques de la piscine</h4>

            <div className="col-4">
              <p>Piscine enterrée</p>
              <p>Structure : parpaing</p>
              <p>Fond : plat</p>
              <p>Revêtement : liner</p>
              <p>Age : 13 ans</p>
            </div>
            <div className="col-4">
              <p>Filtre : sable</p>
              <p>Pompe : 0,75cv</p>
              <p>Traitement : chlore</p>
            </div>
            <div className="col-4">
              <p>Dimensions</p>
              <p>longueur : 8m</p>
              <p>largeur : 3,5m</p>
              <p>profondeur : 1,5m</p>
            </div>
          </div>
        </div>
      </div>
      <div className="bloc">
        <h3>Historique d'intervention</h3>
        <MDBTable align="middle">
          <MDBTableHead>
            <tr>
              <th scope="col">Date</th>
              <th scope="col">Type d'intervention</th>
              <th scope="col">Technicien</th>
              <th scope="col">Action</th>
            </tr>
          </MDBTableHead>
          <MDBTableBody>
            {historique.map((intervention) => (
              <tr>
                <td>{intervention.date} </td>
                <td>
                  <MDBBadge color="primary" pill>
                    {intervention.type_intervention}
                  </MDBBadge>{" "}
                </td>
                <td>{intervention.technicien} </td>
                <td>
                  {" "}
                  <MDBBtn outline rounded>
                    <Link to="/detail-intervention">Voir détails</Link>
                  </MDBBtn>{" "}
                </td>
              </tr>
            ))}
          </MDBTableBody>
        </MDBTable>
      </div>
    </MDBRow>
  );
}

export default DetailIntervention;
