// @ts-nocheck
import {
  MDBBtn,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBSpinner,
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBIcon,
  MDBTabsContent,
  MDBTabsPane,
  MDBCard,
  MDBCardBody,
  MDBCardFooter,
  MDBCardText,
  MDBCardTitle,
} from "mdb-react-ui-kit";
import "./Dashboard.scss";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  BassinInterface,
  ClientInterface,
  EquipementInterface,
  InterventionInterface,
} from "./services/interfaces";
import {
  setDoc,
  doc,
  collection,
  addDoc,
  getDoc,
  getDocs,
  query,
  where,
  deleteDoc,
} from "firebase/firestore";
import { db } from "./services/firebase.config";
import { EQUIPEMENTS, INTERVENTIONS } from "./services/constants";
import { toast } from "react-toastify";
import { QrScanner } from "@yudiel/react-qr-scanner";
import { renderDate } from "./services/common";

function Client({ userUid }: { userUid: string }) {
  const initialClient = {
    nom: "",
    prenom: "",
    telephone: "",
    email: "",
    adresse: "",
    code_postal: "",
    ville: "",
    userUid,
  };

  const { id } = useParams();

  const optionsBassin = [
    { value: "enterree", label: "Enterrée" },
    { value: "debordement", label: "Débordement" },
    { value: "miroir", label: "Miroir" },
    { value: "hors_sol", label: "Hors sol" },
  ];

  const optionsRevetement = [
    { value: "liner", label: "Liner 75/100" },
    { value: "liner_arme", label: "Liner armé 150/100" },
    { value: "carrelage", label: "Carrelage" },
    { value: "polyester", label: "Polyester" },
    { value: "gelcoat", label: "Gelcoat" },
  ];

  const optionsStructure = [
    { value: "parpaing", label: "Parpaing" },
    { value: "polystyrene", label: "Polystyrène" },
    { value: "coque", label: "Coque" },
    { value: "bois", label: "Bois" },
    { value: "acier", label: "Acier" },
    { value: "gonflable", label: "Gonflable" },
    { value: "tubulaire", label: "Tubulaire" },
    { value: "autre", label: "Autre" },
  ];

  const optionsFond = [
    { value: "compose", label: "Composé" },
    { value: "fosse_a_plonger", label: "Fosse à plonger" },
    { value: "incurve", label: "Incurvé" },
    { value: "pente_douce", label: "Pente douce" },
    { value: "plat", label: "Plat" },
  ];

  const optionsEscalier = [
    { value: "aucun", label: "Aucun" },
    { value: "banquette", label: "Banquette" },
    { value: "angle", label: "D'angle" },
    { value: "minos", label: "Minos" },
    { value: "toute_largeur", label: "Toute largeur" },
    { value: "roman", label: "Roman" },
  ];

  const initialBassin = {
    type_revetement: optionsRevetement[0].value,
    type_bassin: optionsBassin[0].value,
    type_structure: optionsStructure[0].value,
    type_fond: optionsFond[0].value,
    type_escalier: optionsEscalier[0].value,
    marque: "",
    modele: "",
    annee: "",
    longeur: "",
    largeur: "",
    profondeur_min: "",
    profondeur_max: "",
  };

  const initialEquipement = {
    nom: EQUIPEMENTS[0].nom,
    marque: "",
    type: EQUIPEMENTS[0]?.type[0],
    modele: "",
    numero_serie: "",
    date_installation: "",
    reference: "",
  };

  const [idClient, setIdclient] = useState<string>("");
  const [client, setClient] = useState<ClientInterface>(initialClient);
  const [equipement, setEquipement] =
    useState<EquipementInterface>(initialEquipement);
  const [bassin, setBassin] = useState<BassinInterface>(initialBassin);
  const [equipements, setEquipements] = useState<EquipementInterface[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showEquipements, setShowEquipements] = useState<boolean>(true);
  const [showQRCode, setShowQRCode] = useState<boolean>(false);
  const [justifyActive, setJustifyActive] = useState("client");
  const equipementsCollection = collection(db, "equipements");
  const [interventions, setInterventions] = useState<InterventionInterface[]>(
    []
  );

  const handleTabClick = (value: string) => {
    if (value === justifyActive) {
      return;
    }

    setJustifyActive(value);
  };
  const clients = collection(db, "clients");

  const deleteEquipement = async (id) => {
    await deleteDoc(doc(db, "equipements", id));
    getEquipements();
  };

  const getEquipements = async () => {
    const q = query(collection(db, "equipements"), where("idClient", "==", id));

    const querySnapshot = await getDocs(q);
    const equipements: EquipementInterface[] = [];
    querySnapshot.forEach((equipement) => {
      equipements.push({
        ...(equipement.data() as EquipementInterface),
        id: equipement.id,
      });
    });
    setEquipements(equipements);
  };

  const getInterventions = async () => {
    const q = query(
      collection(db, "interventions"),
      where("info.client.id", "==", id)
    );

    const querySnapshot = await getDocs(q);

    const result: InterventionInterface[] = [];
    querySnapshot.forEach((intervention) => {
      result.push({
        ...(intervention.data() as InterventionInterface),
        id: intervention.id,
      });
    });
    // @ts-ignore
    const sorted = result.sort((a, b) => b.date - a.date);

    setInterventions(sorted);
  };

  useEffect(() => {
    setClient({ ...client, userUid });
    const getClient = async () => {
      if (id) {
        const docRef = doc(db, "clients", id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setClient(docSnap.data() as ClientInterface);
          setIdclient(id);
        }
      }
    };

    const getBassin = async () => {
      if (id) {
        const docRef = doc(db, "bassins", id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setBassin(docSnap.data() as BassinInterface);
        }
      }
    };

    if (id) {
      setIsLoading(true);
      getClient();
      getBassin();
      getEquipements();
      getInterventions();
      setIsLoading(false);
    }
  }, [id, userUid]);

  const submitClient = async (e: any) => {
    setIsLoading(true);
    e.preventDefault();
    if (!id) {
      const newClient = await addDoc(clients, client);
      setIdclient(newClient.id);
      setJustifyActive("bassin");
    } else {
      await setDoc(doc(db, "clients", id), client);
    }

    window.scrollTo(0, 0);

    setIsLoading(false);
  };

  const lierQRCode = async (QrCode: string) => {
    setShowQRCode(false);
    setIsLoading(true);

    await setDoc(doc(db, "clients", idClient), {
      ...client,
      QrCode,
    });

    setClient({ ...client, QrCode });

    window.scrollTo(0, 0);

    setIsLoading(false);
    toast("QR code ajouté", {
      type: "success",
      autoClose: 5000,
    });
  };

  const submitBassin = async (e: any) => {
    setIsLoading(true);
    e.preventDefault();
    await setDoc(doc(db, "bassins", idClient), bassin);
    window.scrollTo(0, 0);
    setIsLoading(false);
  };

  const submitEquipement = async (e: any) => {
    setIsLoading(true);
    e.preventDefault();
    const result = await addDoc(equipementsCollection, {
      ...equipement,
      idClient,
    });
    if (result && result.id) {
      toast("Equipement sauvegardé", {
        type: "success",
        autoClose: 5000,
      });
    } else {
      toast("Echec lors de l'enregistrement", {
        type: "error",
        autoClose: 5000,
      });
    }
    getEquipements();
    setEquipement(initialEquipement);
    setShowEquipements(!showEquipements);
    window.scrollTo(0, 0);
    setIsLoading(false);
  };

  return (
    <MDBRow>
      {showQRCode && (
        <QrScanner
          onDecode={(result) => lierQRCode(result)}
          onError={(error) =>
            alert("Erreur lors de la lecture : " + error?.message)
          }
        />
      )}
      <div className="bloc">
        {isLoading ? (
          <MDBSpinner className="mx-2" color="info">
            <span className="visually-hidden">Loading...</span>
          </MDBSpinner>
        ) : (
          <>
            <MDBTabs justify className="mb-5">
              <MDBTabsItem>
                <MDBTabsLink
                  onClick={() => handleTabClick("client")}
                  active={justifyActive === "client"}
                >
                  <MDBIcon fas icon="user" className="me-2" />
                  Informations client
                </MDBTabsLink>
              </MDBTabsItem>
              {idClient !== "" && (
                <>
                  <MDBTabsItem>
                    <MDBTabsLink
                      onClick={() => handleTabClick("bassin")}
                      active={justifyActive === "bassin"}
                    >
                      <MDBIcon fas icon="swimming-pool" className="me-2" />{" "}
                      Configuration du bassin
                    </MDBTabsLink>
                  </MDBTabsItem>
                  <MDBTabsItem>
                    <MDBTabsLink
                      onClick={() => handleTabClick("equipements")}
                      active={justifyActive === "equipements"}
                    >
                      <MDBIcon fas icon="clipboard-list" className="me-2" />
                      Equipements
                    </MDBTabsLink>
                  </MDBTabsItem>
                  <MDBTabsItem>
                    <MDBTabsLink
                      onClick={() => handleTabClick("interventions")}
                      active={justifyActive === "interventions"}
                    >
                      <MDBIcon fas icon="calendar-alt" className="me-2" />
                      Interventions
                    </MDBTabsLink>
                  </MDBTabsItem>
                </>
              )}
            </MDBTabs>
            <MDBTabsContent>
              <MDBTabsPane show={justifyActive === "client"}>
                <form onSubmit={submitClient}>
                  <MDBRow className="mb-4">
                    <MDBCol>
                      <MDBInput
                        required
                        value={client.nom}
                        label="Nom"
                        onChange={(e) =>
                          setClient({ ...client, nom: e.target.value })
                        }
                      />
                    </MDBCol>
                    <MDBCol>
                      <MDBInput
                        required
                        value={client.prenom}
                        label="Prénom"
                        onChange={(e) =>
                          setClient({ ...client, prenom: e.target.value })
                        }
                      />
                    </MDBCol>
                  </MDBRow>
                  <MDBRow className="mb-4">
                    <MDBCol>
                      <MDBInput
                        required
                        value={client.telephone}
                        label="Téléphone"
                        onChange={(e) =>
                          setClient({ ...client, telephone: e.target.value })
                        }
                      />
                    </MDBCol>
                    <MDBCol>
                      <MDBInput
                        required
                        value={client.email}
                        label="Email"
                        onChange={(e) =>
                          setClient({ ...client, email: e.target.value })
                        }
                      />
                    </MDBCol>
                  </MDBRow>

                  <MDBInput
                    wrapperClass="mb-4"
                    value={client.adresse}
                    label="Adresse"
                    onChange={(e) =>
                      setClient({ ...client, adresse: e.target.value })
                    }
                  />
                  <MDBRow className="mb-4">
                    <MDBCol md="3">
                      <MDBInput
                        value={client.code_postal}
                        required
                        label="Code postal"
                        onChange={(e) =>
                          setClient({ ...client, code_postal: e.target.value })
                        }
                      />
                    </MDBCol>
                    <MDBCol>
                      <MDBInput
                        required
                        label="Ville"
                        value={client.ville}
                        onChange={(e) =>
                          setClient({ ...client, ville: e.target.value })
                        }
                      />
                    </MDBCol>
                  </MDBRow>
                  <div className="col-12 text-center">
                    {userUid === "o0wrJm7QauMh8OHPfHObgKBe59I3" &&
                      !client.QrCode && (
                        <MDBBtn
                          outline
                          color="primary"
                          size="lg"
                          rounded
                          onClick={() => setShowQRCode(!showQRCode)}
                        >
                          {!showQRCode ? "Lier un QR Code" : "Fermer la webcam"}
                        </MDBBtn>
                      )}
                    &nbsp;
                    <MDBBtn type="submit" color="primary" size="lg" rounded>
                      Enregistrer
                    </MDBBtn>
                  </div>
                </form>
              </MDBTabsPane>
              <MDBTabsPane show={justifyActive === "bassin"}>
                <form onSubmit={submitBassin}>
                  {" "}
                  <MDBRow className="mb-4 ">
                    <div className="col-2">
                      <p>Type de bassin</p>
                    </div>
                    <div className="col-4">
                      <select
                        className="form-control"
                        name="optionBassin"
                        value={bassin.type_bassin}
                        onChange={(e) =>
                          setBassin({ ...bassin, type_bassin: e.target.value })
                        }
                      >
                        {optionsBassin.map((option) => (
                          <option value={option.value}>{option.label}</option>
                        ))}
                      </select>
                    </div>
                    <div className="col-2">
                      <p>Type de structure</p>
                    </div>
                    <div className="col-4">
                      <select
                        className="form-control"
                        name="optionStructure"
                        value={bassin.type_structure}
                        onChange={(e) =>
                          setBassin({
                            ...bassin,
                            type_structure: e.target.value,
                          })
                        }
                      >
                        {optionsStructure.map((option) => (
                          <option value={option.value}>{option.label}</option>
                        ))}
                      </select>
                    </div>
                  </MDBRow>
                  <MDBRow className="mb-4">
                    <div className="col-2">
                      <p>Type de fond</p>
                    </div>
                    <div className="col-4">
                      <select
                        className="form-control"
                        name="optionsFond"
                        value={bassin.type_fond}
                        onChange={(e) =>
                          setBassin({ ...bassin, type_fond: e.target.value })
                        }
                      >
                        {optionsFond.map((option) => (
                          <option value={option.value}>{option.label}</option>
                        ))}
                      </select>
                    </div>
                    <div className="col-2">
                      <p>Type d'escalier</p>
                    </div>
                    <div className="col-4">
                      <select
                        className="form-control"
                        name="optionsEscalier"
                        value={bassin.type_escalier}
                        onChange={(e) =>
                          setBassin({
                            ...bassin,
                            type_escalier: e.target.value,
                          })
                        }
                      >
                        {optionsEscalier.map((option) => (
                          <option value={option.value}>{option.label}</option>
                        ))}
                      </select>
                    </div>
                  </MDBRow>
                  <MDBRow className="mb-4 ">
                    <div className="col-2">
                      <p>Type de revêtement</p>
                    </div>
                    <div className="col-4">
                      <select
                        className="form-control"
                        name="optionBassin"
                        value={bassin.type_revetement}
                        onChange={(e) =>
                          setBassin({
                            ...bassin,
                            type_revetement: e.target.value,
                          })
                        }
                      >
                        {optionsRevetement.map((option) => (
                          <option value={option.value}>{option.label}</option>
                        ))}
                      </select>
                    </div>
                  </MDBRow>
                  <MDBRow className="mb-4">
                    <MDBCol>
                      <MDBInput
                        value={bassin.marque}
                        label="Marque"
                        onChange={(e) =>
                          setBassin({ ...bassin, marque: e.target.value })
                        }
                      />
                    </MDBCol>
                    <MDBCol>
                      <MDBInput
                        value={bassin.modele}
                        label="Modèle"
                        onChange={(e) =>
                          setBassin({ ...bassin, modele: e.target.value })
                        }
                      />
                    </MDBCol>
                  </MDBRow>
                  <MDBRow className="mb-4">
                    <MDBCol>
                      <MDBInput
                        value={bassin.annee}
                        label="Année de construction"
                        onChange={(e) =>
                          setBassin({ ...bassin, annee: e.target.value })
                        }
                      />
                    </MDBCol>
                    <MDBCol>
                      <MDBInput
                        required
                        value={bassin.longeur}
                        label="Longueur"
                        onChange={(e) =>
                          setBassin({ ...bassin, longeur: e.target.value })
                        }
                      />
                    </MDBCol>
                    <MDBCol>
                      <MDBInput
                        required
                        value={bassin.largeur}
                        label="Largeur"
                        onChange={(e) =>
                          setBassin({ ...bassin, largeur: e.target.value })
                        }
                      />
                    </MDBCol>
                    <MDBCol>
                      <MDBInput
                        required
                        value={bassin.profondeur_min}
                        label="Profondeur min"
                        onChange={(e) =>
                          setBassin({
                            ...bassin,
                            profondeur_min: e.target.value,
                          })
                        }
                      />
                    </MDBCol>
                    <MDBCol>
                      <MDBInput
                        required={bassin.type_fond !== "plat"}
                        value={bassin.profondeur_max}
                        label="Profondeur max"
                        onChange={(e) =>
                          setBassin({
                            ...bassin,
                            profondeur_max: e.target.value,
                          })
                        }
                      />
                    </MDBCol>
                  </MDBRow>
                  <div className="col-12 text-center">
                    <MDBBtn type="submit" color="primary" size="lg" rounded>
                      Enregistrer
                    </MDBBtn>
                  </div>
                </form>
              </MDBTabsPane>
              <MDBTabsPane show={justifyActive === "equipements"}>
                {showEquipements && (
                  <MDBRow>
                    <div className="bloc">
                      <h3 className="text-center">Liste des équipements</h3>
                    </div>

                    {equipements.map((equipement) => (
                      <MDBCard>
                        <MDBCardBody>
                          <MDBCardTitle>
                            <div>{equipement.nom}</div>
                          </MDBCardTitle>

                          <MDBCardText>
                            <div>Type : {equipement.type} </div>
                            <div>Marque : {equipement.marque} </div>
                          </MDBCardText>
                          <MDBCardFooter className="text-muted">
                            <MDBBtn
                              outline
                              rounded
                              color="danger"
                              onClick={() => deleteEquipement(equipement.id)}
                            >
                              Supprimer l'équipement
                            </MDBBtn>
                          </MDBCardFooter>
                        </MDBCardBody>
                      </MDBCard>
                    ))}

                    <div className="bloc mb-3 text-center position-sticky">
                      <MDBBtn onClick={() => setShowEquipements(false)} rounded>
                        Ajouter un équipement
                      </MDBBtn>
                    </div>
                  </MDBRow>
                )}
                {!showEquipements && (
                  <form onSubmit={submitEquipement}>
                    {" "}
                    <MDBRow className="mb-4 ">
                      <div className="col-2">
                        <p>Nom de l'équipement</p>
                      </div>
                      <div className="col-4">
                        <select
                          className="form-control"
                          value={equipement.nom}
                          onChange={(e) =>
                            setEquipement({
                              ...equipement,
                              nom: e.target.value,
                              type: EQUIPEMENTS.find(
                                (equip) => equip.nom === e.target.value
                              ).type
                                ? EQUIPEMENTS.find(
                                    (equip) => equip.nom === e.target.value
                                  )?.type[0]
                                : "",
                            })
                          }
                        >
                          {EQUIPEMENTS.map((option) => (
                            <option value={option.nom}>{option.nom}</option>
                          ))}
                        </select>
                      </div>
                      {EQUIPEMENTS.find((equip) => equip.nom === equipement.nom)
                        .type && (
                        <>
                          <div className="col-2">
                            <p>Type</p>
                          </div>
                          <div className="col-4">
                            <select
                              className="form-control"
                              value={equipement.type}
                              onChange={(e) =>
                                setEquipement({
                                  ...equipement,
                                  type: e.target.value,
                                })
                              }
                            >
                              {/* 
// @ts-ignore */}
                              {EQUIPEMENTS.find(
                                (equip) => equip.nom === equipement.nom
                              ).type.map((option) => (
                                <option value={option}>{option}</option>
                              ))}
                            </select>
                          </div>
                        </>
                      )}
                    </MDBRow>
                    <MDBRow className="mb-4">
                      <MDBCol>
                        <MDBInput
                          value={equipement.marque}
                          label="Marque"
                          onChange={(e) =>
                            setEquipement({
                              ...equipement,
                              marque: e.target.value,
                            })
                          }
                        />
                      </MDBCol>
                      <MDBCol>
                        <MDBInput
                          value={equipement.modele}
                          label="Modèle"
                          onChange={(e) =>
                            setEquipement({
                              ...equipement,
                              modele: e.target.value,
                            })
                          }
                        />
                      </MDBCol>
                    </MDBRow>
                    <MDBRow className="mb-4">
                      <MDBCol>
                        <MDBInput
                          value={equipement.numero_serie}
                          label="Numero de série"
                          onChange={(e) =>
                            setEquipement({
                              ...equipement,
                              numero_serie: e.target.value,
                            })
                          }
                        />
                      </MDBCol>
                      <MDBCol>
                        <MDBInput
                          value={equipement.reference}
                          label="Référence"
                          onChange={(e) =>
                            setEquipement({
                              ...equipement,
                              reference: e.target.value,
                            })
                          }
                        />
                      </MDBCol>
                    </MDBRow>
                    <MDBRow className="mb-4">
                      <div className="col-md-6 col-12">
                        <MDBInput
                          value={equipement.date_installation}
                          label="Date d'installation"
                          type="date"
                          onChange={(e) =>
                            setEquipement({
                              ...equipement,
                              date_installation: e.target.value,
                            })
                          }
                        />
                      </div>
                    </MDBRow>
                    <div className="col-12 text-center">
                      <MDBBtn type="submit" color="primary" size="lg" rounded>
                        Enregistrer
                      </MDBBtn>{" "}
                      <MDBBtn
                        onClick={() => setShowEquipements(!showEquipements)}
                        type="submit"
                        color="primary"
                        size="lg"
                        rounded
                        outline
                      >
                        Retour à la liste
                      </MDBBtn>
                    </div>
                  </form>
                )}
              </MDBTabsPane>
              <MDBTabsPane show={justifyActive === "interventions"}>
                {" "}
                <div className="container">
                  <MDBRow>
                    {interventions.map((intervention) => (
                      <MDBCard>
                        <MDBCardBody>
                          <MDBCardTitle>
                            {renderDate(intervention.date)}{" "}
                          </MDBCardTitle>
                          <MDBCardText>
                            <div
                              className="custom-badge"
                              style={{
                                backgroundColor: INTERVENTIONS.find(
                                  (item) => item.nom === intervention.nom
                                )?.couleur,
                              }}
                            >
                              {intervention.nom}
                            </div>
                          </MDBCardText>
                        </MDBCardBody>
                        <MDBCardFooter className="text-muted">
                          <Link
                            to={"/compte-rendu/" + intervention.id}
                            target="_blank"
                          >
                            <MDBBtn outline rounded>
                              Voir le compte rendu
                            </MDBBtn>
                          </Link>
                        </MDBCardFooter>
                      </MDBCard>
                    ))}
                  </MDBRow>
                </div>
              </MDBTabsPane>
            </MDBTabsContent>
          </>
        )}
      </div>
    </MDBRow>
  );
}

export default Client;
