import {
  MDBBtn,
  MDBCheckbox,
  MDBFile,
  MDBModal,
  MDBModalBody,
  MDBModalContent,
  MDBModalDialog,
  MDBModalFooter,
  MDBModalHeader,
  MDBModalTitle,
  MDBRow,
} from "mdb-react-ui-kit";
import Select from "react-select";
import "./App.scss";
import { useState } from "react";

function ClotureIntervention() {
  const [basicModal, setBasicModal] = useState(false);

  const toggleShow = () => setBasicModal(!basicModal);

  const options = [
    { value: "Trouble", label: "Trouble" },
    { value: "Limpide", label: "Limpide" },
  ];

  return (
    <MDBRow>
      <MDBModal show={basicModal} setShow={setBasicModal} tabIndex="-1">
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>
                Valider et envoyer le compte rendu d'intervention
              </MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={toggleShow}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              <div className="col-12 ">
                <textarea className="form-control" rows={5}>
                  Bonjour Monsieur Leroy, Nous sommes intervenu à votre domicile
                  pour réaliser l’intervention de [nettoyage] de votre piscine.
                  Pour consulter le rapport d’intervention depuis votre espace
                  en ligne cliquer ici. Toute l’équipe de [nom société] vous
                  remercions pour votre confiance.
                </textarea>
              </div>
            </MDBModalBody>

            <MDBModalFooter>
              <div className="col-12 text-center">
                <MDBBtn
                  className="mb-3 mt-3"
                  size="lg"
                  color="success"
                  onClick={toggleShow}
                >
                  Envoyer le compte rendu
                </MDBBtn>
              </div>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
      <div className="bloc">
        <div className="row">
          <div className="col-12">
            <h3>Cloturer l'intervention</h3>
          </div>
        </div>
      </div>
      <div className="bloc">
        <div className="row">
          <div className="col-6">
            <h4>Tâches réalisées</h4>
            <MDBCheckbox value="" label="Nettoyage complet du bassin" />
            <MDBCheckbox value="" label="Analyse de l'équilibre de l'eau" />
            <MDBCheckbox value="" label="Tâche xxx" />
          </div>
          <div className="col-6">
            <div className="row mb-3">
              <div className="col-6">
                <h4>Aspect de l'eau</h4>
              </div>
              <div className="col-6">
                <Select
                  defaultValue={{ value: "Trouble", label: "Trouble" }}
                  options={options}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <div className="row mb-2">
                  <div className="col-6">Chlore libre</div>
                  <div className="col-6">
                    <input className="form-control rounded" type="text" />
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-6">Chlore total</div>
                  <div className="col-6">
                    <input className="form-control rounded" type="text" />
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">PH</div>
                  <div className="col-6">
                    <input className="form-control rounded" type="text" />
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div className="row mb-2">
                  <div className="col-6">Alcalinité</div>
                  <div className="col-6">
                    <input className="form-control rounded" type="text" />
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-6">TH</div>
                  <div className="col-6">
                    <input className="form-control rounded" type="text" />
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">Stabilisant</div>
                  <div className="col-6">
                    <input className="form-control rounded" type="text" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bloc">
        <div className="row">
          <h4>Photos</h4>
          <div className="col-12">
            <div>
              <MDBFile label="Ajouter une photo" id="customFile" />
            </div>
          </div>
        </div>
      </div>
      <div className="bloc">
        <div className="row">
          <h4>Commentaires et observations</h4>
          <div className="col-12">
            <textarea className="form-control" rows={5}></textarea>
          </div>
        </div>
        <div className="text-center">
          <MDBBtn
            className="mb-3 mt-3"
            size="lg"
            color="success"
            onClick={toggleShow}
          >
            Valider le compte rendu
          </MDBBtn>
        </div>
      </div>
    </MDBRow>
  );
}

export default ClotureIntervention;
