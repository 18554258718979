import React, { useState } from "react";
import { MDBInput, MDBBtn, MDBSpinner } from "mdb-react-ui-kit";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router-dom";

export default function Login() {
  const [user, setUser] = useState({ email: "", password: "" });
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const navigate = useNavigate();

  const submitLogin = async (e: any) => {
    setIsLoading(true);
    e.preventDefault();
    const auth = getAuth();
    signInWithEmailAndPassword(auth, user.email, user.password).then(() => {
      navigate("/dashboard");
    });
    setIsLoading(false);
  };

  if (isLoading) {
    return (
      <MDBSpinner className="mx-2" color="info">
        <span className="visually-hidden">Loading...</span>
      </MDBSpinner>
    );
  }
  return (
    <form onSubmit={submitLogin} autoComplete="off">
      <MDBInput
        className="mb-4"
        type="email"
        label="Email"
        required
        onChange={(e) => setUser({ ...user, email: e.target.value })}
      />
      <MDBInput
        onChange={(e) => setUser({ ...user, password: e.target.value })}
        className="mb-4"
        type="password"
        label="Mot de passe"
        required
      />
      <MDBBtn type="submit" block>
        Se connecter
      </MDBBtn>
    </form>
  );
}
