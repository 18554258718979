import { MDBBtn, MDBRow } from "mdb-react-ui-kit";
import { useEffect } from "react";
import { getAuth } from "firebase/auth";
import "./Notifications.scss";

function Notifications() {
  const auth = getAuth();
  const user = auth.currentUser;
  const userUid = user ? user.uid : "";

  useEffect(() => {
    console.log(userUid);
    console.log("to do");
  }, []);

  return (
    <MDBRow>
      <div className="bloc">
        <h4 className="mb-3">Suggestions d'interventions à venir</h4>
        <div
          className="alert alert-success"
          role="alert"
          data-mdb-color="success"
        >
          Hivernage à reprogrammer chez Marc Cheminot - Toulouse{" "}
          <MDBBtn rounded color="success" className="btn-notification">
            Voir la dernière intervention
          </MDBBtn>
        </div>
        <div className="alert alert-info" role="alert" data-mdb-color="info">
          Changement de sable à programmer chez Cyril Giraudo - Cugnaux{" "}
          <MDBBtn rounded color="info" className="btn-notification">
            Voir la dernière intervention
          </MDBBtn>
        </div>
        <div
          className="alert alert-success"
          role="alert"
          data-mdb-color="success"
        >
          Etalonnage de sonde à programmer chez Jérémie Gamer - Tournefeuille{" "}
          <MDBBtn rounded color="success" className="btn-notification">
            Voir la dernière intervention
          </MDBBtn>
        </div>
      </div>
    </MDBRow>
  );
}

export default Notifications;
