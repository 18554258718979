import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import CompteRendu from "./CompteRendu";
import QRCodeGenerateur from "./QRCodeGenerateur";
import Scan from "./scan/Scan";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/compte-rendu/:id" element={<CompteRendu />} />
        <Route path="/generer-qrcode/:nombre" element={<QRCodeGenerateur />} />
        <Route path="/scan/:qrcode" element={<Scan />} />
        <Route path="*" element={<App />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);
