import { MDBBtn, MDBCheckbox, MDBRow, MDBSpinner } from "mdb-react-ui-kit";
import "./App.scss";
import { useEffect, useRef, useState } from "react";
import { doc, getDoc } from "firebase/firestore";
import { db } from "./services/firebase.config";
import {
  InterventionInterface,
  TemplateInterface,
} from "./services/interfaces";
import { useParams } from "react-router-dom";
import "./CompteRendu.scss";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import html2PDF from "jspdf-html2canvas";

function CompteRendu() {
  const { id } = useParams();

  const [intervention, setIntervention] = useState<InterventionInterface>();
  const [template, setTemplate] = useState<TemplateInterface>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const getCompteRendu = async () => {
      if (id) {
        const docRef = doc(db, "interventions", id);
        const docSnap = await getDoc(docRef);
        console.log("docSnap : ", docSnap);
        if (docSnap.exists()) {
          const data = docSnap.data() as InterventionInterface;
          // @ts-ignore
          const date = new Date(data.date.seconds * 1000);

          setIntervention({ ...data, date });

          const docRef = doc(db, "templates", data.userUid);
          const docSnapTemplate = await getDoc(docRef);
          if (docSnapTemplate.exists()) {
            setTemplate(docSnapTemplate.data() as TemplateInterface);
          }
        }
      }
    };

    setIsLoading(true);
    getCompteRendu();

    setIsLoading(false);
  }, []);
  const couvertureRef = useRef(null);
  const clientRef = useRef(null);

  const cgvRef = useRef(null);
  const tachesRealiseesRef = useRef(null);
  const fonctionnementRef = useRef(null);
  const raisonsocialeRef = useRef(null);

  const dlPDF = async () => {
    const doc = new jsPDF({
      format: "a4",
      unit: "cm",
    });

    // @ts-ignore
    const couvertureCanvas = await html2canvas(couvertureRef.current, {
      useCORS: true,
    });

    const couvertureImg = couvertureCanvas.toDataURL("image/png");

    doc.addImage(couvertureImg, "JPEG", 1, 1, 19, 25.7);
    doc.addPage();
    // @ts-ignore
    const clientCanvas = await html2canvas(clientRef.current, {
      useCORS: true,
    });

    const clientImg = clientCanvas.toDataURL("image/png");

    doc.addImage(clientImg, "JPEG", 1, 1, 19, 25.7);
    doc.addPage();

    if (markupCgv.__html && markupCgv.__html?.length > 0) {
      // @ts-ignore
      const cgvCanvas = await html2canvas(cgvRef.current, {
        useCORS: true,
      });

      const cgvImg = cgvCanvas.toDataURL("image/png");

      doc.addImage(cgvImg, "JPEG", 1, 1, 19, 25.7);
      doc.addPage();
    }
    // @ts-ignore
    const tachesCanvas = await html2canvas(tachesRealiseesRef.current, {
      useCORS: true,
    });

    const tachesImg = tachesCanvas.toDataURL("image/png");

    doc.addImage(tachesImg, "JPEG", 1, 1, 19, 25.7);
    doc.addPage();

    if (
      intervention?.tempsFiltration ||
      intervention?.remiseEnRouteAuto ||
      intervention?.remiseEnRouteAuto ||
      intervention?.remiseEnRouteChauffage ||
      intervention?.securiteBassin ||
      intervention?.qualiteCloture ||
      intervention?.chloreLibre ||
      intervention?.chloreTotal ||
      intervention?.PH ||
      intervention?.alcalinite ||
      intervention?.TH ||
      intervention?.stabilisant
    ) {
      const fonctionnementCanvas = await html2canvas(
        // @ts-ignore
        fonctionnementRef.current,
        {
          useCORS: true,
        }
      );

      const fonctionnementImg = fonctionnementCanvas.toDataURL("image/png");

      doc.addImage(fonctionnementImg, "JPEG", 1, 1, 19, 25.7);
      doc.addPage();
    }
    // @ts-ignore
    const raisonsocialeCanvas = await html2canvas(raisonsocialeRef.current, {
      useCORS: true,
    });

    const raisonsocialeImg = raisonsocialeCanvas.toDataURL("image/png");

    doc.addImage(raisonsocialeImg, "JPEG", 1, 1, 19, 25.7);

    doc.save("compterendu.pdf");

    /*const pages = document.getElementsByClassName("page-to-print");
    // @ts-ignore
    html2PDF(pages, {
      jsPDF: {
        format: "a4",
      },
      imageType: "image/jpeg",
      output: "./pdf/generate.pdf",
    });*/

    /*const element = document.getElementById("element-to-print");
    console.log("element : ", element);

    const opt = {
      margin: 0,
      filename: "time_sheet_report.pdf",
      image: { type: "jpeg", quality: 0.2 },
      html2canvas: { scale: 2, useCORS: true },
      jsPDF: { unit: "cm", format: "a4", orientation: "p" },
    };
    // @ts-ignore
    html2pdf().set(opt).from(element).save();
    // @ts-ignore
    //html2pdf(element);*/
  };

  const markupFooter = { __html: template?.footer };
  const markupRaisonSociale = { __html: template?.raisonSociale };
  const markupCgv = { __html: template?.cgv };
  const markupCommentaires = { __html: intervention?.commentaires };
  const markupCommentairesCloture = {
    __html: intervention?.commentairesCloture,
  };

  return (
    <div className="compte-rendu">
      <div className="bloc mb-3 text-center sticky-top">
        <MDBBtn onClick={() => dlPDF()} rounded>
          Télécharger en PDF
        </MDBBtn>
      </div>
      {isLoading ? (
        <MDBSpinner className="mx-2" color="info">
          <span className="visually-hidden">Loading...</span>
        </MDBSpinner>
      ) : (
        <>
          <MDBRow className="page-to-print" ref={couvertureRef}>
            <div className="col-3 offset-1">
              <img
                src={template?.logo}
                className="img-fluid logo-compte-rendu"
                alt="..."
              />
            </div>{" "}
            <div className="col-7 offset-1">
              <h1 className="titre-compte-rendu">{template?.titre}</h1>
              <h4>{intervention?.nom}</h4>
              <h4>{intervention?.date.toLocaleDateString("fr-FR")}</h4>
            </div>
            <div className="col-4 offset-4">
              {" "}
              <img
                src={template?.imageCouverture}
                className="img-fluid image-couverture-compte-rendu"
                alt="..."
              />
            </div>
            {/* 
// @ts-ignore */}
            <div dangerouslySetInnerHTML={markupFooter}></div>
          </MDBRow>{" "}
          <MDBRow className="page-to-print" ref={clientRef}>
            <div className="col-3 offset-1">
              <img
                src={template?.logo}
                className="img-fluid logo-compte-rendu"
                alt="..."
              />
            </div>{" "}
            <div className="info-client col-8 offset-2">
              <div className="titre-situation mb-4">Information client :</div>
              <p>
                {intervention?.info.client.nom}{" "}
                {intervention?.info.client.prenom}
              </p>
              <p>{intervention?.info.client.telephone}</p>
              <p>{intervention?.info.client.email}</p>
              <p>{intervention?.info.client.adresse}</p>
              <p>
                {intervention?.info.client.code_postal}{" "}
                {intervention?.info.client.ville}
              </p>
              <p>
                {intervention?.technicien?.nom}{" "}
                {intervention?.technicien?.prenom}
              </p>
            </div>
            {/* 
// @ts-ignore */}
            {intervention?.images.length > 0 && (
              <div className="photo-situation col-8 offset-2">
                <div className="titre-situation">Photo de situation</div>
                <MDBRow>
                  {/* 
// @ts-ignore */}
                  {intervention.images.map((url) => (
                    <div className="mb-4 col-8 offset-2" key={url}>
                      <img
                        src={url}
                        className="img-fluid img-thumbnail rounded"
                        alt=""
                      />
                    </div>
                  ))}
                </MDBRow>
              </div>
            )}
            {markupCommentaires.__html && markupCommentaires.__html?.length && (
              <div className="col-8 offset-2">
                <div className="titre-situation">Commentaires</div>
                {/* 
// @ts-ignore */}
                <div dangerouslySetInnerHTML={markupCommentaires}></div>
              </div>
            )}
            {/* 
// @ts-ignore */}
            <div dangerouslySetInnerHTML={markupFooter}></div>
          </MDBRow>{" "}
          {markupCgv.__html && markupCgv.__html?.length > 0 && (
            <MDBRow className="page-to-print" ref={cgvRef}>
              <div className="titre-situation mb-4">
                Décharge et responsabilité
              </div>
              {/* 
// @ts-ignore */}
              <div dangerouslySetInnerHTML={markupCgv}></div>
            </MDBRow>
          )}
          <MDBRow className="page-to-print" ref={tachesRealiseesRef}>
            <div className="col-8 offset-2">
              <div className="titre-situation mb-5">Taches réalisées</div>

              <div className="row">
                {intervention?.taches.map((tache: any) => (
                  <div className="col-6">
                    {" "}
                    <MDBCheckbox value={tache} label={tache} checked />
                  </div>
                ))}
              </div>
            </div>
            {/* 
// @ts-ignore */}
            <div dangerouslySetInnerHTML={markupCommentairesCloture}></div>
          </MDBRow>
          <MDBRow className="page-to-print" ref={fonctionnementRef}>
            <div className="col-8 offset-2 ">
              <div className="titre-situation mb-5">
                Fonctionnement de la piscine
              </div>

              <div className="row">
                {" "}
                <div className="col-6">
                  {intervention?.tempsFiltration && (
                    <div>
                      Temps de filtration (h/jour) :{" "}
                      {intervention?.tempsFiltration}
                    </div>
                  )}
                  {intervention?.remiseEnRouteAuto && (
                    <div>
                      <MDBCheckbox
                        label={"Remise en route traitement automatique"}
                        checked={intervention?.remiseEnRouteAuto}
                      />
                    </div>
                  )}
                  {intervention?.remiseEnRouteChauffage && (
                    <div>
                      <MDBCheckbox
                        label={"Remise en route chauffage"}
                        checked={intervention?.remiseEnRouteChauffage}
                      />
                    </div>
                  )}
                  {intervention?.securiteBassin && (
                    <div>
                      <MDBCheckbox
                        label={"Mise en sécurité du bassin"}
                        checked={intervention?.securiteBassin}
                      />
                    </div>
                  )}
                </div>
                <div className="col-6">
                  {" "}
                  {intervention?.qualiteCloture && (
                    <div>Aspect de l'eau : {intervention?.qualiteCloture}</div>
                  )}
                  {intervention?.chloreLibre && (
                    <div>Chlore libre : {intervention?.chloreLibre}</div>
                  )}
                  {intervention?.chloreTotal && (
                    <div>Chlore total : {intervention?.chloreTotal}</div>
                  )}
                  {intervention?.PH && <div>PH : {intervention?.PH}</div>}
                  {intervention?.alcalinite && (
                    <div>Alcalinité : {intervention?.alcalinite}</div>
                  )}
                  {intervention?.TH && <div>TH : {intervention?.TH}</div>}
                  {intervention?.stabilisant && (
                    <div>Stabilisant : {intervention?.stabilisant}</div>
                  )}
                </div>
              </div>
              <div className="row mt-5">
                {/* 
// @ts-ignore */}
                {intervention?.imagesCloture &&
                  intervention.imagesCloture.map((url) => (
                    <div className="mb-4 col-6" key={url}>
                      <img
                        src={url}
                        className="img-fluid img-thumbnail rounded"
                        alt=""
                      />
                    </div>
                  ))}
              </div>
            </div>
          </MDBRow>
          <MDBRow className="page-to-print" ref={raisonsocialeRef}>
            {/* 
// @ts-ignore */}
            <div dangerouslySetInnerHTML={markupRaisonSociale}></div>
          </MDBRow>
        </>
      )}
    </div>
  );
}

export default CompteRendu;
