import {
  MDBRow,
  MDBSpinner,
  MDBCheckbox,
  MDBCol,
  MDBBtn,
} from "mdb-react-ui-kit";
import "./Dashboard.scss";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  BassinInterface,
  ClientInterface,
  InterventionInterface,
  OptionInterface,
  TechnicienInterface,
} from "./services/interfaces";
import {
  doc,
  collection,
  getDoc,
  getDocs,
  query,
  where,
  addDoc,
  setDoc,
} from "firebase/firestore";
import { db } from "./services/firebase.config";
import Select from "react-select";

import {
  CLOUDINARY_CONFIG,
  Categories,
  INTERVENTIONS,
  TINYMCE_API_KEY,
} from "./services/constants";
import { registerLocale } from "react-datepicker";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fr from "date-fns/locale/fr";
import "./Intervention.scss";
import { toast } from "react-toastify";
import { Editor } from "@tinymce/tinymce-react";
registerLocale("fr", fr);

function Intervention({ userUid }: { userUid: string }) {
  const { id } = useParams();

  const initialClient = {
    nom: "",
    prenom: "",
    telephone: "",
    email: "",
    adresse: "",
    code_postal: "",
    ville: "",
    userUid,
  };

  const initialBassin = {
    type_revetement: "",
    type_bassin: "",
    type_structure: "",
    type_fond: "",
    type_escalier: "",
    marque: "",
    modele: "",
    annee: "",
    longeur: "",
    largeur: "",
    profondeur_min: "",
    profondeur_max: "",
  };

  const initialIntervention = {
    QrCode: "",
    qualite: "Verte",
    qualiteCloture: "Verte",
    userUid,
    info: { client: initialClient, bassin: initialBassin },
    images: [],
    imagesCloture: [],
    nom: INTERVENTIONS[0].nom,
    commentaires: "",
    commentairesCloture: "",
    date: new Date(),
    taches: [],
    status: "created",
    remiseEnRouteAuto: false,
    tempsFiltration: "",
    remiseEnRouteChauffage: false,
    securiteBassin: false,
    chloreLibre: "",
    chloreTotal: "",
    PH: "",
    alcalinite: "",
    TH: "",
    stabilisant: "",
  };
  const [categorie, setCategorie] = useState<string>("");
  const [intervention, setIntervention] =
    useState<InterventionInterface>(initialIntervention);
  const [clientsOptions, setClientsOptions] = useState<OptionInterface[]>([]);
  const [techniciensOptions, setTechniciensOptions] = useState<
    OptionInterface[]
  >([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [typeInterventionsOptions, setTypeInterventionsOptions] = useState<
    OptionInterface[]
  >(
    INTERVENTIONS.filter((item) => item.categorie === Categories[0].label).map(
      (type) => {
        return { value: type.nom, label: type.nom };
      }
    )
  );

  const navigate = useNavigate();

  const interventions = collection(db, "interventions");

  const editTaches = (option: string) => {
    let taches = [];
    if (intervention.taches.some((tache) => tache === option)) {
      taches = intervention.taches.filter(
        (intervention) => intervention !== option
      );
    } else {
      taches = intervention.taches;
      taches.push(option);
    }

    setIntervention({ ...intervention, taches });
  };

  const openWidget = (cloture: boolean) => {
    // @ts-ignore
    const cloudinaryWidget = window.cloudinary.createUploadWidget(
      CLOUDINARY_CONFIG,
      // @ts-ignore
      (error, result) => {
        if (!error && result && result.event === "success") {
          console.log("intervention: ", intervention);
          console.log("Done! Here is the image info: ", result.info);
          console.log("cloture: ", cloture);
          const newImg = cloture
            ? intervention.imagesCloture
            : intervention.images;
          console.log("newImg: ", newImg);
          newImg.push(result.info.secure_url);
          if (cloture) {
            setIntervention({ ...intervention, imagesCloture: newImg });
          } else setIntervention({ ...intervention, images: newImg });
          console.log("intervention: ", intervention);
        }
      }
    );
    cloudinaryWidget.open();
  };

  useEffect(() => {
    const getIntervention = async () => {
      if (id) {
        const docRef = doc(db, "interventions", id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data() as InterventionInterface;
          // @ts-ignore
          const date = new Date(data.date.seconds * 1000);
          setIntervention({ ...data, date });
        }
      }
    };
    const getClientsOptions = async () => {
      const q = query(
        collection(db, "clients"),
        where("userUid", "==", userUid)
      );

      const querySnapshot = await getDocs(q);

      const options: OptionInterface[] = [];

      querySnapshot.forEach((client) => {
        const option = client.data() as ClientInterface;

        options.push({ label: option.nom, value: client.id });
      });
      setClientsOptions(options);
    };

    const getTechniciensOptions = async () => {
      const q = query(
        collection(db, "techniciens"),
        where("userUid", "==", userUid)
      );

      const querySnapshot = await getDocs(q);

      const options: OptionInterface[] = [];

      querySnapshot.forEach((technicien) => {
        const option = technicien.data() as TechnicienInterface;

        options.push({ label: option.prenom, value: technicien.id });
      });
      setTechniciensOptions(options);
    };

    getClientsOptions();
    getTechniciensOptions();

    if (id) {
      setIsLoading(true);
      getIntervention();
      setIsLoading(false);
    }
  }, [id, userUid]);

  const submitForm = async (status?: string) => {
    setIsLoading(true);

    if (!id) {
      console.log("intervention avant la sauvergarde : ", intervention);
      await addDoc(interventions, { ...intervention, status });
      navigate("/dashboard");
    } else {
      await setDoc(doc(db, "interventions", id), { ...intervention, status });
      setIntervention({
        ...intervention,
        status: status || intervention.status,
      });
      toast("Intervention sauvegardée", {
        type: "success",
        autoClose: 5000,
      });
      window.scrollTo(0, 0);
    }

    setIsLoading(false);
  };

  const handleSelectClientChange = async (newValue: any) => {
    const clientsRef = doc(db, "clients", newValue?.value);
    const clientSnap = await getDoc(clientsRef);
    if (clientSnap.exists()) {
      let bassin = initialBassin;

      const bassinsRef = doc(db, "bassins", newValue?.value);
      const bassinSnap = await getDoc(bassinsRef);
      if (bassinSnap.exists()) {
        bassin = bassinSnap.data() as BassinInterface;
      }

      const clientData = clientSnap.data() as ClientInterface;

      setIntervention({
        ...intervention,
        QrCode: clientData.QrCode || "",
        info: {
          client: {
            ...clientData,
            id: clientSnap.id,
          },
          bassin,
        },
      });
    }
  };

  const handleSelectTechnicienChange = async (newValue: any) => {
    const technicienRef = doc(db, "techniciens", newValue?.value);
    const technicienSnap = await getDoc(technicienRef);
    if (technicienSnap.exists()) {
      setIntervention({
        ...intervention,
        technicien: {
          ...(technicienSnap.data() as TechnicienInterface),
          id: technicienSnap.id,
        },
      });
    }
  };

  return (
    <MDBRow>
      {isLoading ? (
        <MDBSpinner className="mx-2" color="info">
          <span className="visually-hidden">Loading...</span>
        </MDBSpinner>
      ) : intervention.status === "created" ? (
        <form>
          {" "}
          <div className="bloc mb-3">
            <div className="row mb-3">
              {!id && (
                <>
                  <div className="col-12 col-md-3">
                    <h4>Sélectionnez un client</h4>
                  </div>
                  <div className="col-12 col-md-3 mb-3">
                    <Select
                      defaultValue={clientsOptions[0]}
                      options={clientsOptions}
                      onChange={(newValue) =>
                        handleSelectClientChange(newValue)
                      }
                    />
                  </div>
                </>
              )}
              <div className="col-12 col-md-3">
                <h4>Date de l'intervention</h4>
              </div>
              <div className="col-12 col-md-3">
                <DatePicker
                  className="form-control"
                  showTimeSelect
                  selected={intervention.date}
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  timeCaption="Heure"
                  dateFormat="d MMMM yyyy, H:mm"
                  withPortal
                  locale="fr"
                  onChange={(date) =>
                    date && setIntervention({ ...intervention, date })
                  }
                />
              </div>
            </div>
          </div>
          {intervention.info.client.id && (
            <>
              {" "}
              <div className="bloc">
                <div className="row">
                  <div className="col-md-6 col-12">
                    <h4 className="mb-3">Information clients</h4>
                    <p>
                      {intervention.info.client.nom}{" "}
                      {intervention.info.client.prenom}
                    </p>
                    <p>{intervention.info.client.adresse}</p>
                    <p>
                      {intervention.info.client.code_postal}{" "}
                      {intervention.info.client.ville}
                    </p>
                    <p>{intervention.info.client.telephone}</p>
                    <p>{intervention.info.client.email}</p>
                  </div>
                  <div className="col-md-6 col-12">
                    <div className="row">
                      <h4 className="mb-3">Caractéristiques de la piscine</h4>

                      <div className="col-md-4 col-12">
                        <p>
                          Type de bassin :{" "}
                          {intervention.info.bassin.type_bassin}
                        </p>
                        <p>
                          Structure : {intervention.info.bassin.type_structure}
                        </p>
                        <p>Fond : {intervention.info.bassin.type_fond}</p>
                        <p>
                          Revêtement :{" "}
                          {intervention.info.bassin.type_revetement}
                        </p>
                        <p>
                          Année de construction :{" "}
                          {intervention.info.bassin.annee}
                        </p>
                      </div>
                      <div className="col-md-4 col-12">
                        <p>Marque : {intervention.info.bassin.marque}</p>
                        <p>Modèle : {intervention.info.bassin.modele}</p>
                      </div>
                      <div className="col-md-4 col-12">
                        <p>Dimensions</p>
                        <p>longueur :{intervention.info.bassin.longeur}</p>
                        <p>largeur : {intervention.info.bassin.largeur}</p>
                        <p>
                          profondeur min :{" "}
                          {intervention.info.bassin.profondeur_min}
                        </p>
                        <p>
                          profondeur min :{" "}
                          {intervention.info.bassin.profondeur_max}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bloc">
                <div className="row">
                  <div className="col-12  mb-3">
                    <h4 className="mb-3">Détail de l'intervention</h4>

                    {!id && techniciensOptions.length > 0 && (
                      <div className="row mb-3">
                        <div className="col-12 col-md-6">
                          <p>Sélectionnez un technicien</p>
                        </div>
                        <div className="col-12 col-md-6">
                          <Select
                            defaultValue={techniciensOptions[0]}
                            options={techniciensOptions}
                            onChange={(newValue) =>
                              handleSelectTechnicienChange(newValue)
                            }
                          />
                        </div>
                      </div>
                    )}
                    {!id && (
                      <>
                        <div className="row mb-3">
                          <div className="col-12 col-md-6">
                            <p>Sélectionnez une catégorie</p>
                          </div>
                          <div className="col-12 col-md-6">
                            <select
                              className="form-control"
                              value={categorie}
                              onChange={(e) => {
                                setIntervention({
                                  ...intervention,
                                  // @ts-ignore
                                  nom: INTERVENTIONS.find(
                                    (item) => item.categorie === e.target.value
                                  )?.nom,
                                  taches: [],
                                });
                                setTypeInterventionsOptions(
                                  INTERVENTIONS.filter(
                                    (item) => item.categorie === e.target.value
                                  ).map((type) => {
                                    return { value: type.nom, label: type.nom };
                                  })
                                );
                                setCategorie(e.target.value);
                              }}
                            >
                              {Categories.map((option) => (
                                <option value={option.label}>
                                  {option.label}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col-12 col-md-6">
                            <p>Sélectionnez un type d'intervention</p>
                          </div>
                          <div className="col-12 col-md-6">
                            <Select
                              defaultValue={typeInterventionsOptions[0]}
                              value={typeInterventionsOptions.find(
                                (type) => type.value === intervention.nom
                              )}
                              options={typeInterventionsOptions}
                              onChange={(newValue) =>
                                setIntervention({
                                  ...intervention,
                                  // @ts-ignore
                                  nom: INTERVENTIONS.find(
                                    (type) => type.nom === newValue?.value
                                  )?.nom,
                                  taches: [],
                                })
                              }
                            />
                          </div>
                        </div>
                      </>
                    )}
                    <h5>Liste des tâches à réaliser</h5>
                    {INTERVENTIONS.find(
                      (item) => item.nom === intervention.nom
                    )?.taches.map((tache: any) => (
                      <p>- {tache.label}</p>
                    ))}
                    <div className="row mt-3">
                      <div className="col-md-6">
                        {" "}
                        <h5>Aspect de l'eau</h5>
                      </div>
                      <div className="col-md-6">
                        <select
                          className="form-control"
                          defaultValue={intervention.qualite}
                          value={intervention.qualite}
                          onChange={(e) =>
                            setIntervention({
                              ...intervention,
                              qualite: e.target.value,
                            })
                          }
                        >
                          <option value="Verte">Verte</option>
                          <option value="Trouble">Trouble</option>
                          <option value="Limpide">Limpide</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <h4 className="mb-3">Commentaires et observations</h4>
                    <Editor
                      apiKey={TINYMCE_API_KEY}
                      init={{
                        menubar: false,
                        plugins:
                          "anchor autolink charmap link lists searchreplace table visualblocks wordcount",
                        toolbar:
                          "forecolor backcolor | fontfamily fontsize | bold italic underline | link | align lineheight | numlist bullist",
                      }}
                      value={intervention.commentaires}
                      onEditorChange={(newValue) => {
                        setIntervention({
                          ...intervention,
                          commentaires: newValue,
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="bloc">
                <div className="row">
                  <div className="col-12">
                    <div className="text-center mt-3 mb-3">
                      <MDBBtn
                        id="upload_widget"
                        onClick={(e) => {
                          e.preventDefault();
                          openWidget(false);
                        }}
                        color="primary"
                        size="lg"
                        rounded
                        outline
                      >
                        Ajouter une photo
                      </MDBBtn>
                    </div>
                  </div>
                </div>
                <MDBRow>
                  {intervention.images.map((url) => (
                    <MDBCol lg="4" md="12" className="mb-4" key={url}>
                      <img
                        src={url}
                        className="img-fluid img-thumbnail rounded"
                        alt=""
                      />
                    </MDBCol>
                  ))}
                </MDBRow>
              </div>
              <div className="bloc">
                <div className="text-center">
                  <MDBBtn
                    className="mb-3 me-3"
                    color="primary"
                    size="lg"
                    rounded
                    onClick={() => submitForm("created")}
                  >
                    Enregistrer
                  </MDBBtn>
                  {id && (
                    <MDBBtn
                      className="mb-3"
                      color="success"
                      size="lg"
                      rounded
                      onClick={() => submitForm("ongoing")}
                    >
                      Cloturer l'intervention
                    </MDBBtn>
                  )}
                </div>
              </div>
            </>
          )}
        </form>
      ) : (
        <form>
          <div className="bloc">
            <div className="row">
              <div className="col-12  mb-3">
                <h4 className="mb-3">Tâches réalisées</h4>

                {INTERVENTIONS.find(
                  (item) => item.nom === intervention.nom
                )?.taches.map((tache: any) => (
                  <MDBCheckbox
                    onClick={(e) => editTaches(tache.label)}
                    value={tache.label}
                    label={tache.label}
                    checked={intervention.taches.some(
                      (tacheSelected) => tache.label === tacheSelected
                    )}
                  />
                ))}
                <div className="row mt-3">
                  <h4>Fonctionnement de la piscine</h4>
                  <div className="col-md-6 col-12">
                    <div className="row">
                      <div className="col-12 col-md-6">
                        Temps de filtration (h/jour)
                      </div>
                      <div className="col-12 col-md-6">
                        <input
                          value={intervention.tempsFiltration}
                          onChange={(e) =>
                            setIntervention({
                              ...intervention,
                              tempsFiltration: e.target.value,
                            })
                          }
                          className="form-control rounded"
                          type="text"
                        />
                      </div>
                    </div>
                    <MDBCheckbox
                      onClick={(e) =>
                        setIntervention({
                          ...intervention,
                          remiseEnRouteAuto: !intervention.remiseEnRouteAuto,
                        })
                      }
                      label={"Remise en route traitement automatique"}
                      checked={intervention.remiseEnRouteAuto}
                    />
                    <MDBCheckbox
                      onClick={(e) =>
                        setIntervention({
                          ...intervention,
                          remiseEnRouteChauffage:
                            !intervention.remiseEnRouteChauffage,
                        })
                      }
                      label={"Remise en route chauffage"}
                      checked={intervention.remiseEnRouteChauffage}
                    />
                    <MDBCheckbox
                      onClick={(e) =>
                        setIntervention({
                          ...intervention,
                          securiteBassin: !intervention.securiteBassin,
                        })
                      }
                      label={"Mise en sécurité du bassin"}
                      checked={intervention.securiteBassin}
                    />
                  </div>

                  <div className="col-md-6 col-12">
                    <div className="row">
                      <div className="col-md-3  col-12">
                        <h5>Aspect de l'eau</h5>
                      </div>
                      <div className="col-md-9 col-12 mb-3">
                        {" "}
                        <select
                          className="form-control"
                          defaultValue={intervention.qualiteCloture}
                          value={intervention.qualiteCloture}
                          onChange={(e) =>
                            setIntervention({
                              ...intervention,
                              qualiteCloture: e.target.value,
                            })
                          }
                        >
                          <option value="Verte">Verte</option>
                          <option value="Trouble">Trouble</option>
                          <option value="Limpide">Limpide</option>
                        </select>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 col-12">
                        <div className="row mb-2">
                          <div className="col-md-6 col-12">Chlore libre</div>
                          <div className="col-md-6 col-12">
                            <input
                              value={intervention.chloreLibre}
                              onChange={(e) =>
                                setIntervention({
                                  ...intervention,
                                  chloreLibre: e.target.value,
                                })
                              }
                              className="form-control rounded"
                              type="text"
                            />
                          </div>
                        </div>
                        <div className="row mb-2">
                          <div className="col-md-6 col-12">Chlore total</div>
                          <div className="col-md-6 col-12">
                            <input
                              value={intervention.chloreTotal}
                              onChange={(e) =>
                                setIntervention({
                                  ...intervention,
                                  chloreTotal: e.target.value,
                                })
                              }
                              className="form-control rounded"
                              type="text"
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6 col-12">PH</div>
                          <div className="col-md-6 col-12">
                            <input
                              value={intervention.PH}
                              onChange={(e) =>
                                setIntervention({
                                  ...intervention,
                                  PH: e.target.value,
                                })
                              }
                              className="form-control rounded"
                              type="text"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 col-12">
                        <div className="row mb-2">
                          <div className="col-md-6 col-12">Alcalinité</div>
                          <div className="col-md-6 col-12">
                            <input
                              value={intervention.alcalinite}
                              onChange={(e) =>
                                setIntervention({
                                  ...intervention,
                                  alcalinite: e.target.value,
                                })
                              }
                              className="form-control rounded"
                              type="text"
                            />
                          </div>
                        </div>
                        <div className="row mb-2">
                          <div className="col-md-6 col-12">TH</div>
                          <div className="col-md-6 col-12">
                            <input
                              value={intervention.TH}
                              onChange={(e) =>
                                setIntervention({
                                  ...intervention,
                                  TH: e.target.value,
                                })
                              }
                              className="form-control rounded"
                              type="text"
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6 col-12">Stabilisant</div>
                          <div className="col-md-6 col-12">
                            <input
                              value={intervention.stabilisant}
                              onChange={(e) =>
                                setIntervention({
                                  ...intervention,
                                  stabilisant: e.target.value,
                                })
                              }
                              className="form-control rounded"
                              type="text"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <h4 className="mb-3">Commentaires et observations</h4>
                <Editor
                  apiKey={TINYMCE_API_KEY}
                  init={{
                    menubar: false,
                    plugins:
                      "anchor autolink charmap link lists searchreplace table visualblocks wordcount",
                    toolbar:
                      "forecolor backcolor | fontfamily fontsize | bold italic underline | link | align lineheight | numlist bullist",
                  }}
                  value={intervention.commentairesCloture}
                  onEditorChange={(newValue) => {
                    setIntervention({
                      ...intervention,
                      commentairesCloture: newValue,
                    });
                  }}
                />
              </div>
            </div>
          </div>
          <div className="bloc">
            <div className="row">
              <div className="col-12">
                <div className="text-center mt-3 mb-3">
                  <MDBBtn
                    id="upload_widget"
                    onClick={(e) => {
                      e.preventDefault();
                      openWidget(true);
                    }}
                    color="primary"
                    size="lg"
                    rounded
                    outline
                  >
                    Ajouter une photo
                  </MDBBtn>
                </div>
              </div>
            </div>
            <MDBRow>
              {intervention.imagesCloture.map((url) => (
                <MDBCol lg="4" md="12" className="mb-4" key={url}>
                  <img
                    src={url}
                    className="img-fluid img-thumbnail rounded"
                    alt=""
                  />
                </MDBCol>
              ))}
            </MDBRow>
          </div>
          <div className="bloc">
            <div className="text-center">
              <MDBBtn
                className="mb-3 me-3"
                color="primary"
                size="lg"
                rounded
                onClick={() => submitForm("closed")}
              >
                Enregistrer et accéder au compte rendu
              </MDBBtn>
            </div>
          </div>
        </form>
      )}
    </MDBRow>
  );
}

export default Intervention;
