import {
  MDBTable,
  MDBTableHead,
  MDBTableBody,
  MDBBtn,
  MDBRow,
  MDBCard,
  MDBCardBody,
  MDBCardFooter,
  MDBCardText,
  MDBCardTitle,
} from "mdb-react-ui-kit";
import "./App.scss";
import { Link } from "react-router-dom";
import { query, collection, where, getDocs } from "firebase/firestore";
import { useState, useEffect } from "react";
import { db } from "./services/firebase.config";
import { TechnicienInterface } from "./services/interfaces";
import { Categories } from "./services/constants";

function Techniciens({ userUid }: { userUid: string }) {
  const [techniciens, setTechniciens] = useState<TechnicienInterface[]>([]);

  useEffect(() => {
    const getTechniciens = async () => {
      const q = query(
        collection(db, "techniciens"),
        where("userUid", "==", userUid)
      );

      const querySnapshot = await getDocs(q);
      const techniciens: TechnicienInterface[] = [];
      querySnapshot.forEach((technicien) => {
        techniciens.push({
          ...(technicien.data() as TechnicienInterface),
          id: technicien.id,
          userUid,
        });
      });
      setTechniciens(techniciens);
    };
    getTechniciens();
  }, [userUid]);

  return (
    <MDBRow>
      <div className="bloc">
        <h3 className="text-center">Liste des techniciens</h3>
      </div>
      {techniciens.map((technicien) => (
        <MDBCard>
          <MDBCardBody>
            <MDBCardTitle>
              <div>
                {technicien.prenom} {technicien.nom}
              </div>
            </MDBCardTitle>

            <MDBCardText>
              <div className="row">
                <div className="col-12">
                  <div>{technicien.telephone}</div>{" "}
                  {technicien.competences.map((competence: string) => (
                    <div
                      className="custom-badge"
                      style={{
                        backgroundColor: Categories.find(
                          (item) => item.label === competence
                        )?.couleur,
                      }}
                    >
                      {competence}
                    </div>
                  ))}
                </div>
              </div>
            </MDBCardText>
            <MDBCardFooter className="text-muted">
              <Link to={"/techniciens/edit/" + technicien.id}>
                <MDBBtn outline rounded>
                  Modifier
                </MDBBtn>
              </Link>
            </MDBCardFooter>
          </MDBCardBody>
        </MDBCard>
      ))}

      <div className="bloc mb-3 text-center position-sticky">
        <Link to="/techniciens/edit">
          <MDBBtn outline rounded>
            Ajouter un technicien
          </MDBBtn>
        </Link>
      </div>
    </MDBRow>
  );
}

export default Techniciens;
