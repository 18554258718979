// @ts-nocheck

import {
  MDBBtn,
  MDBRow,
  MDBCardTitle,
  MDBCard,
  MDBCardFooter,
  MDBCardText,
  MDBCardBody,
} from "mdb-react-ui-kit";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { db } from "../src/services/firebase.config";
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { ClientInterface } from "./services/interfaces";

function Clients({ userUid }: { userUid: string }) {
  const [clients, setClients] = useState<ClientInterface[]>([]);

  const getClients = async () => {
    const q = query(collection(db, "clients"), where("userUid", "==", userUid));

    const querySnapshot = await getDocs(q);
    const clients: ClientInterface[] = [];
    querySnapshot.forEach((client) => {
      clients.push({
        ...(client.data() as ClientInterface),
        id: client.id,
        userUid,
      });
    });
    setClients(clients);
  };

  useEffect(() => {
    getClients();
  }, [userUid]);

  const deleteClient = async (id) => {
    if (window.confirm("Veuillez confirmer la suppression de ce client")) {
      await deleteDoc(doc(db, "clients", id));
      getClients();
    }
  };

  return (
    <MDBRow>
      <div className="bloc">
        <h3 className="text-center">Liste des clients</h3>
      </div>

      {clients.map((client) => (
        <MDBCard>
          <MDBCardBody>
            <MDBCardTitle>
              <div>
                {client.nom} {client.prenom}
              </div>
            </MDBCardTitle>

            <MDBCardText>
              <div>{client.telephone} </div>
              <div>{client.ville} </div>
            </MDBCardText>
            <MDBCardFooter className="text-muted">
              <Link to={"/clients/edit/" + client.id}>
                <MDBBtn outline rounded>
                  Voir détails
                </MDBBtn>
              </Link>
              &nbsp;
              <MDBBtn
                outline
                rounded
                color="danger"
                onClick={() => deleteClient(client.id)}
              >
                Supprimer ce client
              </MDBBtn>
            </MDBCardFooter>
          </MDBCardBody>
        </MDBCard>
      ))}

      <div className="bloc mb-3 text-center position-sticky">
        <Link to="/clients/edit">
          <MDBBtn rounded>Ajouter un client</MDBBtn>
        </Link>
      </div>
    </MDBRow>
  );
}

export default Clients;
