import {
  MDBBtn,
  MDBRow,
  MDBSpinner,
  MDBCard,
  MDBCardBody,
  MDBCardFooter,
  MDBCardText,
  MDBCardTitle,
} from "mdb-react-ui-kit";
import "./Dashboard.scss";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { InterventionInterface } from "./services/interfaces";
import { query, collection, where, getDocs } from "firebase/firestore";
import { db } from "./services/firebase.config";
import { INTERVENTIONS } from "./services/constants";
import { renderDate } from "./services/common";

function Dashboard({ userUid }: { userUid: string }) {
  const [interventions, setInterventions] = useState<InterventionInterface[]>(
    []
  );

  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);

    const getInterventions = async () => {
      const q = query(
        collection(db, "interventions"),
        where("userUid", "==", userUid)
      );

      const querySnapshot = await getDocs(q);

      const result: InterventionInterface[] = [];
      querySnapshot.forEach((intervention) => {
        console.log("intervention.data() : ", intervention.data());
        result.push({
          ...(intervention.data() as InterventionInterface),
          id: intervention.id,
        });
      });
      // @ts-ignore
      const sorted = result.sort((a, b) => b.date - a.date);

      setInterventions(sorted);
    };
    getInterventions();
    setIsLoading(false);
  }, [userUid]);

  if (isLoading) {
    return (
      <MDBSpinner className="mx-2" color="info">
        <span className="visually-hidden">Loading...</span>
      </MDBSpinner>
    );
  }

  return (
    <MDBRow>
      <div className="bloc">
        <h3 className="text-center">Liste des interventions</h3>
      </div>

      {interventions.map((intervention) => (
        <MDBCard>
          <MDBCardBody>
            <MDBCardTitle>{renderDate(intervention.date)} </MDBCardTitle>
            <MDBCardText>
              <div>{intervention.info.client.nom} </div>
              <div>{intervention.info.client.ville} </div>
              <div>{intervention.info.client.telephone} </div>
              <div
                className="custom-badge"
                style={{
                  backgroundColor: INTERVENTIONS.find(
                    (item) => item.nom === intervention.nom
                  )?.couleur,
                }}
              >
                {intervention.nom}
              </div>
            </MDBCardText>
          </MDBCardBody>
          <MDBCardFooter className="text-muted">
            {" "}
            {intervention.status === "closed" ? (
              <Link to={"/compte-rendu/" + intervention.id} target="_blank">
                <MDBBtn outline rounded>
                  Voir le compte rendu
                </MDBBtn>
              </Link>
            ) : (
              <Link to={"/interventions/edit/" + intervention.id}>
                <MDBBtn outline rounded>
                  Modifier
                </MDBBtn>
              </Link>
            )}
          </MDBCardFooter>
        </MDBCard>
      ))}
      <div className="bloc mb-3 text-center position-sticky">
        <Link to="/interventions/edit">
          <MDBBtn rounded>Ajouter une intervention</MDBBtn>
        </Link>
      </div>
    </MDBRow>
  );
}

export default Dashboard;
