import * as React from "react";
import { QRCode } from "react-qrcode-logo";
import { useParams } from "react-router-dom";
import "./QRCodeGenerateur.scss";
import { MDBBtn } from "mdb-react-ui-kit";
import { jsPDF } from "jspdf";
import { useRef } from "react";
import html2canvas from "html2canvas";

function QRCodeGenerateur() {
  const [liste, setliste] = React.useState<string[]>([]);

  const { nombre } = useParams();
  const doc = new jsPDF();

  const generateRandomString = (length: number) => {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  React.useEffect(() => {
    const listeTemp = [];
    // @ts-ignore
    for (let i = 0; i < nombre; i++) {
      listeTemp.push(
        "https://poolcare-24731.web.app/scan/" + generateRandomString(10)
      );
    }
    setliste(listeTemp);
  }, []);

  const itemsRefs = useRef([]);
  const reportTemplateRef = useRef(null);
  // @ts-ignore
  const handleGeneratePdf = (element) => {
    itemsRefs.current.forEach(async (ref, index) => {
      // @ts-ignore
      const doc = new jsPDF({
        format: "a4",
        unit: "cm",
      });
      doc.setLineWidth(0.01);
      const canvas = await html2canvas(ref);

      const img = canvas.toDataURL("image/png");

      // @ts-ignore
      doc.addImage(img, "JPEG", 2, 2);
      doc.line(4.5, 2, 7.5, 2);
      doc.line(4.5, 2, 4.5, 5);
      doc.line(4.5, 5, 7.5, 5);
      doc.line(7.5, 2, 7.5, 5);
      doc.save("qrcode.pdf");
    });
  };

  return (
    <div className="QRCodeGenerateur">
      <div className="col-12 text-center mb-5">
        <MDBBtn
          color="primary"
          size="lg"
          rounded
          onClick={() => handleGeneratePdf(reportTemplateRef.current)}
        >
          Télécharger le PDF
        </MDBBtn>
      </div>
      <div ref={reportTemplateRef}>
        <div className="page row">
          {liste.map((item, index) => (
            <div
              key={index}
              // @ts-ignore
              ref={(el) => (itemsRefs.current[index] = el)}
              className="text-center mb-5 qrcode"
            >
              {" "}
              <p>CARNET ENTRETIEN</p>
              <QRCode
                fgColor="#2290CF"
                removeQrCodeBehindLogo
                ecLevel={"Q"}
                logoPaddingStyle="circle"
                logoImage={
                  process.env.PUBLIC_URL + "/assets/icone-poolcare.png"
                }
                value={item}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default QRCodeGenerateur;
