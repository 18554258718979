export const Categories = [
  { label: "Recherche de fuite", couleur: "#FFCC66" },
  { label: "Maintenance", couleur: "#9966FF" },
  { label: "Entretien", couleur: "#66CC99" },
  { label: "Dépannage", couleur: "#FF6666" },
  { label: "Rénovation", couleur: "#FFB266" },
  { label: "Installation", couleur: "#4DB8FF" },
];

export const EQUIPEMENTS = [
  {
    nom: "Pompe de filtration",
    type: ["Monophasé", "Triphasé", "Variable"],
    marque: "",
    modele: "",
    numero_serie: "",
    date_installation: "",
    reference: "",
  },
  {
    nom: "Filtre",
    type: ["Sable", "Verre recyclé", "Cartouche", "Diatomée"],
    marque: "",
    modele: "",
    numero_serie: "",
    date_installation: "",
    reference: "",
  },
  {
    nom: "Coffret électrique",
    type: ["Connecté", "Non connecté"],
    marque: "",
    modele: "",
    numero_serie: "",
    date_installation: "",
    reference: "",
  },
  {
    nom: "Éclairage",
    type: ["Halogène", "Leds"],
    marque: "",
    modele: "",
    numero_serie: "",
    date_installation: "",
    reference: "",
  },
  {
    nom: "Coffret hors gel",
    marque: "",
    modele: "",
    numero_serie: "",
    date_installation: "",
    reference: "",
  },
  {
    nom: "Revêtement",
    type: [
      "Liner 75/100",
      "Membrane armée",
      "Carrelage",
      "Polyester",
      "Gelcoat",
    ],
    marque: "",
    modele: "",
    date_installation: "",
    reference: "",
  },
  {
    nom: "Volet automatique",
    type: ["Hors-sol", "Immergé"],
    marque: "",
    modele: "",
    numero_serie: "",
    date_installation: "",
    reference: "",
  },
  {
    nom: "Bâche",
    type: [
      "Bâche à barres",
      "Bâche hiver",
      "Bâche hiver sécurité",
      "Bâche à bulles",
      "Bâche filet",
    ],
    marque: "",
    modele: "",
    numero_serie: "",
    date_installation: "",
    reference: "",
  },
  {
    nom: "Barrière",
    type: ["Verre", "Alu", "Filet", "Bois"],
    marque: "",
    modele: "",
    numero_serie: "",
    date_installation: "",
    reference: "",
  },
  {
    nom: "Chauffage",
    type: ["Pompe à chaleur", "Réchauffeur", "Panneau solaire", "Échangeur"],
    marque: "",
    modele: "",
    numero_serie: "",
    date_installation: "",
    reference: "",
  },
  {
    nom: "Électrolyse",
    marque: "",
    modele: "",
    numero_serie: "",
    date_installation: "",
    reference: "",
  },
  {
    nom: "Lampe UV",
    marque: "",
    modele: "",
    numero_serie: "",
    date_installation: "",
    reference: "",
  },
  {
    nom: "Régulateur Redox",
    marque: "",
    modele: "",
    numero_serie: "",
    date_installation: "",
    reference: "",
  },
  {
    nom: "Régulateur pH",
    marque: "",
    modele: "",
    numero_serie: "",
    date_installation: "",
    reference: "",
  },
  {
    nom: "Ozonateur",
    marque: "",
    modele: "",
    numero_serie: "",
    date_installation: "",
    reference: "",
  },
  {
    nom: "Chlorinateur",
    marque: "",
    modele: "",
    numero_serie: "",
    date_installation: "",
    reference: "",
  },
  {
    nom: "Brominateur",
    marque: "",
    modele: "",
    numero_serie: "",
    date_installation: "",
    reference: "",
  },
  {
    nom: "Nage à contre courant",
    marque: "",
    modele: "",
    numero_serie: "",
    date_installation: "",
    reference: "",
  },
  {
    nom: "Douche",
    type: ["Simple", "Solaire"],
    marque: "",
    modele: "",
    numero_serie: "",
    date_installation: "",
    reference: "",
  },
  {
    nom: "Robot",
    type: ["Électrique", "Hydraulique", "À pression"],
    marque: "",
    modele: "",
    numero_serie: "",
    date_installation: "",
    reference: "",
  },
  {
    nom: "Surpresseur",
    marque: "",
    modele: "",
    numero_serie: "",
    date_installation: "",
    reference: "",
  },
];

export const INTERVENTIONS = [
  {
    categorie: "Recherche de fuite",
    couleur: "#FFCC66",
    materiel: "Bassin",
    nom: "Recherche de fuite",
    taches: [
      { label: "Constatation visuelle" },
      { label: "Constatation visuelle local technique" },
      { label: "Test colorant fluorescéine" },
      { label: "Test par dépression" },
      { label: "Test de mise sous pression" },
      { label: "Test caméra endoscopique" },
      { label: "Test gaz traceur" },
      { label: "Test par électro-induction" },
      { label: "Détection de réseau hydraulique" },
    ],
  },
  {
    categorie: "Maintenance",
    couleur: "#9966FF",
    materiel: "Pompe",
    nom: "Remplacement pièces d'usure de la pompe",
    taches: [
      { label: "Remplacement ailette ventilateur" },
      { label: "Remplacement bouchon/joint purge" },
      { label: "Remplacement capot ventilateur" },
      { label: "Remplacement condensateur" },
      { label: "Remplacement couvercle de pompe" },
      { label: "Remplacement de joint" },
      { label: "Remplacement du corps de pompe" },
      { label: "Remplacement du joint de corps" },
      { label: "Remplacement du panier de préfiltre" },
      { label: "Remplacement raccord de pompe" },
    ],
  },
  {
    categorie: "Maintenance",
    couleur: "#9966FF",
    materiel: "Filtre",
    nom: "Remplacement pièces d'usure du filtre",
    taches: [
      { label: "Remplacement collecteur" },
      { label: "Remplacement couvercle" },
      { label: "Remplacement de crépines" },
      { label: "Remplacement de la poignée" },
      { label: "Remplacement de la vanne 6 voies" },
      { label: "Remplacement diffuseur" },
      { label: "Remplacement du boisseau" },
      { label: "Remplacement du joint étoiles" },
      { label: "Remplacement joint" },
      { label: "Remplacement raccord" },
      { label: "Remplacement tube évent" },
      { label: "Remplacement vidange/purge" },
    ],
  },
  {
    categorie: "Maintenance",
    couleur: "#9966FF",
    materiel: "Électrolyse",
    nom: "Remplacement pièces d'usure de l'électrolyse",
    taches: [
      { label: "Remplacement cable d'alimentation" },
      { label: "Remplacement cellule" },
      { label: "Remplacement de la carte électronique" },
      { label: "Remplacement des joints" },
      { label: "Remplacement du capteur de débit" },
      { label: "Remplacement fusibles" },
      { label: "Remplacement joint" },
      { label: "Remplacement raccord de cellule" },
    ],
  },
  {
    categorie: "Maintenance",
    couleur: "#9966FF",
    materiel: "Régulateur pH",
    nom: "Remplacement pièces d'usure du régulateur pH",
    taches: [
      { label: "Remplacement cable alimentation" },
      { label: "Remplacement canne d'injection" },
      { label: "Remplacement crépine d'aspiration" },
      { label: "Remplacement de la carte électronique" },
      { label: "Remplacement des galets" },
      { label: "Remplacement electro aimant" },
      { label: "Remplacement joint de corps/raccord" },
      { label: "Remplacement moteur de pompe/corps de pompe" },
      { label: "Remplacement porte sonde" },
      { label: "Remplacement sonde de niveau" },
      { label: "Remplacement sonde pH" },
      { label: "Remplacement tube de compression" },
      { label: "Remplacement tube refoulement/aspiration" },
    ],
  },
  {
    categorie: "Maintenance",
    couleur: "#9966FF",
    materiel: "Régulateur Redox",
    nom: "Remplacement pièces d'usure du régulateur Redox",
    taches: [
      { label: "Remplacement cable alimentation" },
      { label: "Remplacement canne d'injection" },
      { label: "Remplacement crépine d'aspiration" },
      { label: "Remplacement de la carte électronique" },
      { label: "Remplacement des galets" },
      { label: "Remplacement electro aimant" },
      { label: "Remplacement joint de corps/raccord" },
      { label: "Remplacement moteur de pompe/corps de pompe" },
      { label: "Remplacement porte sonde" },
      { label: "Remplacement sonde de niveau" },
      { label: "Remplacement sonde Redox" },
      { label: "Remplacement tube de compression" },
      { label: "Remplacement tube refoulement/aspiration" },
    ],
  },
  {
    categorie: "Maintenance",
    couleur: "#9966FF",
    materiel: "Skimmer",
    nom: "Remplacement pièces d'usure du skimmer",
    taches: [
      { label: "Remplacement de la bride et/ou des vis" },
      { label: "Remplacement des joints" },
      { label: "Remplacement du cache bride" },
      { label: "Remplacement du panier de skimmer" },
      { label: "Remplacement du volet" },
    ],
  },
  {
    categorie: "Maintenance",
    couleur: "#9966FF",
    materiel: "Buses de refoulement",
    nom: "Remplacement pièces d'usure des buses de refoulement",
    taches: [
      { label: "Remplacement de la bride et/ou des vis" },
      { label: "Remplacement des joints" },
      { label: "Remplacement du cache bride" },
    ],
  },
  {
    categorie: "Maintenance",
    couleur: "#9966FF",
    materiel: "Pompe à chaleur",
    nom: "Remplacement pièces d'usure de la pompe à chaleur",
    taches: [
      { label: "Recharge fluide frigorigène" },
      { label: "Remplacement cable alimentation" },
      { label: "Remplacement de l'échangeur" },
      { label: "Remplacement de la carte électronique" },
      { label: "Remplacement de la sonde thermostat" },
      { label: "Remplacement de l'hélice moteur ventilateur" },
      { label: "Remplacement des fusibles" },
      { label: "Remplacement des joints et raccords" },
      { label: "Remplacement du bouton trois position" },
      { label: "Remplacement du capot de protection" },
      { label: "Remplacement du capteur de débit" },
      { label: "Remplacement du capteur de température" },
      { label: "Remplacement du compresseur" },
      { label: "Remplacement du condensateur" },
      { label: "Remplacement du pressostat" },
      { label: "Remplacement du thermostat" },
    ],
  },
  {
    categorie: "Maintenance",
    couleur: "#9966FF",
    materiel: "Volets",
    nom: "Remplacement pièces d'usure du volet automatique",
    taches: [
      { label: "Remplacement cable alimentation" },
      { label: "Remplacement de joints" },
      { label: "Remplacement de l'axe" },
      { label: "Remplacement de la commande/télécommande/boitier clé" },
      { label: "Remplacement des lames" },
      { label: "Remplacement des platines de fixation" },
      { label: "Remplacement des sangles" },
      { label: "Remplacement du boitier de commande" },
      { label: "Remplacement du capteur de fin de course" },
      { label: "Remplacement du contact sec" },
      { label: "Remplacement du moteur" },
      { label: "Remplacement enjoliveur d'accroche de sécurité" },
      { label: "Remplacement ski pour tablier de lames" },
      { label: "Vérification et ajustement du niveau d'eau" },
      { label: "Contrôle et correction de l'équilibre de l'eau" },
      { label: "Ajout du produit d'hivernage" },
      { label: "Réglage du temps de filtration" },
      { label: "Mise en route du coffret hors-gel" },
      { label: "Installation de la couverture de piscine" },
      { label: "Déconnexion du système de chauffage" },
      { label: "Déconnexion des appareils de traitement automatique" },
      { label: "Mise en sécurité des équipements et accessoires" },
      { label: "Nettoyage du local technique" },
    ],
  },
  {
    categorie: "Entretien",
    couleur: "#66CC99",
    materiel: "Bassin",
    nom: "Remise en route",
    taches: [
      { label: "Retrait et nettoyage de la couverture" },
      { label: "Vérification et ajustement du niveau d'eau" },
      { label: "Vérification du système de filtration" },
      { label: "Nettoyage des paniers de skimmers et du préfiltre" },
      { label: "Contrôle et correction de l'équilibre de l'eau" },
      { label: "Nettoyage du bassin" },
      { label: "Traitement choc" },
      { label: "Réglage du temps de filtration" },
    ],
  },
  {
    categorie: "Entretien",
    couleur: "#66CC99",
    materiel: "Bassin",
    nom: "Nettoyage",
    taches: [
      { label: "Passage de l'épuisette à la surface et au fond du bassin" },
      { label: "Brossage des parois et du fond" },
      { label: "Aspiration du fond de la piscine" },
      { label: "Nettoyage préfiltre de la pompe et panier de skimmer" },
      { label: "Contre lavage du filtre" },
      { label: "Contrôle et correction de l'équilibre de l'eau" },
      { label: "Contrôle et ajustement du niveau d'eau" },
    ],
  },
  {
    categorie: "Entretien",
    couleur: "#66CC99",
    materiel: "Bassin",
    nom: "Contrôle de l'équilibre de l'eau",
    taches: [
      { label: "Contrôle et correction de l'équilibre de l'eau" },
      { label: "Contrôle et correction du niveau de désinfectant" },
    ],
  },
  {
    categorie: "Entretien",
    couleur: "#66CC99",
    materiel: "Filtre",
    nom: "Nettoyage filtre",
    taches: [
      { label: "Contre lavage du filtre" },
      { label: "Ajout d'un produit détartrant" },
      { label: "Lavage rinçage du filtre" },
      { label: "Contrôle et ajustement du niveau d'eau" },
      { label: "Réglage du temps de filtration" },
    ],
  },
  {
    categorie: "Entretien",
    couleur: "#66CC99",
    materiel: "Plage",
    nom: "Nettoyage des plages",
    taches: [
      { label: "Brossage des plages" },
      { label: "Application d'un liquide anti-fongique" },
      { label: "Rinçage au jet haute pression" },
    ],
  },
  {
    categorie: "Entretien",
    couleur: "#66CC99",
    materiel: "Couverture automatique",
    nom: "Nettoyage volet",
    taches: [
      { label: "Nettoyage du tablier" },
      { label: "Nettoyage de la fosse" },
      { label: "Nettoyage des caillebotis" },
      { label: "Contrôle et ajustement du niveau d'eau" },
      { label: "Réglage du temps de filtration" },
      { label: "Mise en déchêterie du média filtrant usagé" },
    ],
  },
  {
    categorie: "Entretien",
    couleur: "#66CC99",
    materiel: "Sonde traitement automatique",
    nom: "Étalonnage sonde",
    taches: [
      { label: "Mise en sécurité de l'installation" },
      { label: "Étalonnage de la sonde" },
      { label: "Réglage des points des consignes" },
      { label: "Remise en service de la filtration et réglage" },
    ],
  },
  {
    categorie: "Entretien",
    couleur: "#66CC99",
    materiel: "Sonde",
    nom: "Remplacement de sonde",
    taches: [
      { label: "Retrait de l'ancienne sonde" },
      { label: "Installation de la nouvelle sonde" },
      { label: "Étalonnage de la sonde" },
      { label: "Réglage des points de consigne" },
      { label: "Réglage du temps de filtration" },
    ],
  },
  {
    categorie: "Entretien",
    couleur: "#66CC99",
    materiel: "Électrolyseur",
    nom: "Remplacement cellule électrolyseur",
    taches: [
      { label: "Mise en sécurité de l'installation" },
      { label: "Démontage de l'ancienne cellule" },
      { label: "Installation de la cellule" },
      { label: "Mise en pression du système hydraulique" },
      { label: "Vérification du niveau de sel" },
      { label: "Réglage du niveau de désinfection" },
      { label: "Contrôle et correction de l'équilibre de l'eau" },
    ],
  },
  {
    categorie: "Dépannage",
    couleur: "#FF6666",
    materiel: "Structure de la piscine",
    nom: "Réparation de fuite",
    taches: [
      { label: "Identification de l'origine de la fuite" },
      { label: "Réparation de la fuite" },
      { label: "Test d'étanchéité" },
    ],
  },
  {
    categorie: "Dépannage",
    couleur: "#FF6666",
    materiel: "Filtre",
    nom: "Filtre",
    taches: [
      { label: "Diagnostic de la panne" },
      { label: "Remplacement de pièces usées ou défectueuses" },
      { label: "Test de fonctionnement et réglage du temps de filtration" },
    ],
  },
  {
    categorie: "Dépannage",
    couleur: "#FF6666",
    materiel: "Pompe",
    nom: "Pompe",
    taches: [
      { label: "Diagnostic de la panne" },
      { label: "Remplacement de pièces usées ou défectueuses" },
      { label: "Test de fonctionnement et réglage du temps de filtration" },
    ],
  },
  {
    categorie: "Dépannage",
    couleur: "#FF6666",
    materiel: "Électrolyseur",
    nom: "Électrolyseur",
    taches: [
      { label: "Diagnostic du dysfonctionnement" },
      { label: "Remplacement de la cellule ou autres composants" },
      { label: "Réglage des paramètres et test de fonctionnement" },
    ],
  },
  {
    categorie: "Dépannage",
    couleur: "#FF6666",
    materiel: "Éclairage",
    nom: "Éclairage piscine",
    taches: [
      { label: "Diagnostic du problème d'éclairage" },
      { label: "Remplacement des ampoules ou du système" },
      { label: "Vérification du câblage électrique" },
      { label: "Réglage et test de fonctionnement" },
    ],
  },
  {
    categorie: "Dépannage",
    couleur: "#FF6666",
    materiel: "Chauffage",
    nom: "Chauffage piscine",
    taches: [
      { label: "Diagnostic du dysfonctionnement" },
      { label: "Remplacement des composants défectueux" },
      { label: "Réglage et test de fonctionnement" },
    ],
  },
  {
    categorie: "Dépannage",
    couleur: "#FF6666",
    materiel: "Volet automatique",
    nom: "Volet automatique",
    taches: [
      { label: "Diagnostic du problème" },
      { label: "Remplacement de moteur, lames, ou autres pièces" },
      { label: "Test de fonctionnement" },
    ],
  },
  {
    categorie: "Rénovation",
    couleur: "#FFB266",
    materiel: "Revêtement résine",
    nom: "Résine",
    taches: [
      { label: "Vidange du bassin" },
      { label: "Prise de côtes" },
      { label: "Dépose du revêtement existant" },
      { label: "Réparation du support" },
      { label: "Nettoyage du support" },
      { label: "Préparation du support" },
      { label: "Application de la résine" },
      { label: "Application du gelcoat de finition" },
      { label: "Mise en eau" },
      { label: "Mise en déchêterie du matériel usagé" },
    ],
  },
  {
    categorie: "Rénovation",
    couleur: "#FFB266",
    materiel: "Revêtement liner 75/100",
    nom: "Liner 75/100",
    taches: [
      { label: "Vidange du bassin" },
      { label: "Prise de côtes" },
      { label: "Dépose du revêtement existant" },
      { label: "Nettoyage du support" },
      { label: "Remplacement des rails d'accroche" },
      { label: "Application liquide anti-microbien" },
      { label: "Pose du géotextile" },
      { label: "Pose du liner" },
      { label: "Mise en eau" },
      { label: "Bridage des pièces à sceller" },
      { label: "Mise en déchêterie du matériel usagé" },
    ],
  },
  {
    categorie: "Rénovation",
    couleur: "#FFB266",
    materiel: "Revêtement liner 150/100",
    nom: "Membrane armée",
    taches: [
      { label: "Vidange du bassin" },
      { label: "Prise de côtes" },
      { label: "Dépose du revêtement existant" },
      { label: "Nettoyage du support" },
      { label: "Remplacement des rails d'accroche" },
      { label: "Application liquide anti-microbien" },
      { label: "Pose du géotextile" },
      { label: "Pose et soudure de la membrane armée" },
      { label: "Mise en eau" },
      { label: "Bridage des pièces à sceller" },
      { label: "Mise en déchêterie du matériel usagé" },
    ],
  },
  {
    categorie: "Rénovation",
    couleur: "#FFB266",
    materiel: "Margelles",
    nom: "Margelles",
    taches: [
      { label: "Dépose des margelles existantes" },
      { label: "Réparation de l'arase" },
      { label: "Nettoyage du support" },
      { label: "Pose des margelles" },
      { label: "Pose des joints" },
      { label: "Mise en déchêterie du matériel usagé" },
    ],
  },
  {
    categorie: "Rénovation",
    couleur: "#FFB266",
    materiel: "Plage",
    nom: "Plages",
    taches: [
      { label: "Dépose du dallage existant" },
      { label: "Réparation du support" },
      { label: "Nettoyage du support" },
      { label: "Pose des dalles" },
      { label: "Pose des joints" },
      { label: "Mise en déchêterie du matériel usagé" },
    ],
  },
  {
    categorie: "Rénovation",
    couleur: "#FFB266",
    materiel: "Pièces à sceller",
    nom: "Pièces à sceller",
    taches: [
      { label: "Vidange du bassin" },
      { label: "Dépose des pièces à sceller" },
      { label: "Mise en place des nouvelles pièces à sceller" },
      { label: "Scellement et étanchéité" },
      { label: "Raccordement hydraulique" },
      { label: "Test et vérification" },
      { label: "Mise en eau" },
      { label: "Mise en déchêterie du matériel usagé" },
    ],
  },
  {
    categorie: "Rénovation",
    couleur: "#FFB266",
    materiel: "Pompe",
    nom: "Pompe",
    taches: [
      { label: "Dépose de la pompe existante" },
      { label: "Installation de la pompe" },
      { label: "Raccordement hydraulique" },
      { label: "Branchement électrique" },
      { label: "Test et vérification" },
      { label: "Mise en pression" },
      { label: "Réglage du temps de filtration" },
      { label: "Mise en déchêterie du matériel usagé" },
    ],
  },
  {
    categorie: "Rénovation",
    couleur: "#FFB266",
    materiel: "Filtre",
    nom: "Filtre",
    taches: [
      { label: "Dépose du filtre existant" },
      { label: "Installation du filtre" },
      { label: "Raccordement hydraulique" },
      { label: "Mise en place du média filtrant" },
      { label: "Test et vérification" },
      { label: "Mise en pression" },
      { label: "Réglage du temps de filtration" },
      { label: "Mise en déchêterie du matériel usagé" },
    ],
  },
  {
    categorie: "Rénovation",
    couleur: "#FFB266",
    materiel: "Filtration complète",
    nom: "Filtration",
    taches: [
      { label: "Dépose des équipements existants" },
      { label: "Installation de la pompe" },
      { label: "Installation du filtre" },
      { label: "Raccordement hydraulique" },
      { label: "Test et vérification" },
      { label: "Mise en pression" },
      { label: "Réglage du temps de filtration" },
    ],
  },
  {
    categorie: "Rénovation",
    couleur: "#FFB266",
    materiel: "Réseau hydraulique",
    nom: "Réseau hydraulique",
    taches: [
      { label: "Décaissement" },
      { label: "Dépose du réseau hydraulique" },
      { label: "Installation du réseau hydraulique" },
      { label: "Test et vérification" },
      { label: "Remblai" },
    ],
  },
  {
    categorie: "Installation",
    couleur: "#4DB8FF",
    materiel: "Liner 75/100",
    nom: "Pose liner",
    taches: [
      { label: "Prise de côtes" },
      { label: "Pose des rails d'accroche" },
      { label: "Application liquide anti-microbien" },
      { label: "Pose du géotextille" },
      { label: "Pose du liner" },
      { label: "Mise en eau" },
      { label: "Bridage des pièces à sceller" },
    ],
  },
  {
    categorie: "Installation",
    couleur: "#4DB8FF",
    materiel: "Membrane armée 150/100",
    nom: "Pose membrane armée",
    taches: [
      { label: "Prise de côtes" },
      { label: "Pose des rails d'accroche" },
      { label: "Application liquide anti-microbien" },
      { label: "Pose du géotextille" },
      { label: "Pose et soudure de la membrane armée" },
      { label: "Mise en eau" },
      { label: "Bridage des pièces à sceller" },
    ],
  },
  {
    categorie: "Installation",
    couleur: "#4DB8FF",
    materiel: "Système de filtration",
    nom: "Système de filtration",
    taches: [
      { label: "Installation du filtre" },
      { label: "Installation de la pompe" },
      { label: "Raccordement au réseau hydraulique et électrique" },
      { label: "Mise en service et test" },
    ],
  },
  {
    categorie: "Installation",
    couleur: "#4DB8FF",
    materiel: "Chauffage",
    nom: "Chauffage piscine",
    taches: [
      { label: "Installation du système de chauffage" },
      { label: "Raccordement électrique et hydraulique" },
      { label: "Mise en service et test" },
    ],
  },
  {
    categorie: "Installation",
    couleur: "#4DB8FF",
    materiel: "Éclairage",
    nom: "Éclairage piscine",
    taches: [
      { label: "Installation des projecteurs" },
      { label: "Raccordement électrique" },
      { label: "Test de fonctionnement" },
    ],
  },
  {
    categorie: "Installation",
    couleur: "#4DB8FF",
    materiel: "Bâche de piscine hiver",
    nom: "Installation de bâche de piscine",
    taches: [
      { label: "Placement et centrage de la bâche" },
      { label: "Vérification des débordements" },
      { label: "Installation des fixations" },
      { label: "Fixation de la bâche" },
      { label: "Contrôle de sécurité" },
    ],
  },
  {
    categorie: "Installation",
    couleur: "#4DB8FF",
    materiel: "Barrière",
    nom: "Barrière de sécurité",
    taches: [
      { label: "Perçage de la dalle" },
      { label: "Fixation des panneaux et des poteaux" },
      { label: "Installation du portillon" },
    ],
  },
  {
    categorie: "Installation",
    couleur: "#4DB8FF",
    materiel: "Alarme",
    nom: "Installation d'alarme'",
    taches: [
      { label: "Fixation du support sur margelle ou parois" },
      { label: "Mise en place de l'alarme" },
      { label: "Mise en service électrique" },
      { label: "Test de déclenchement" },
    ],
  },
  {
    categorie: "Installation",
    couleur: "#4DB8FF",
    materiel: "Volet automatique immergé",
    nom: "Volet automatique immergé",
    taches: [
      { label: "Préparer arrivée électrique et câbles" },
      { label: "Préparer margelles pour supports" },
      { label: "Respecter parallélisme et centrer couverture" },
      { label: "Assembler lames PVC et régler jeu latéral" },
      { label: "Raccorder lames à l'axe" },
      { label: "Installer mise à la terre" },
      { label: "Ouvrir volet pour vérification" },
      { label: "Régler fins de course" },
      { label: "Installer supports de poutres et caillebotis" },
      { label: "Installer et ajuster caillebotis" },
      { label: "Fixer boucles anti-soulèvements" },
      { label: "Encliqueter sangles pour verrouillage" },
    ],
  },
  {
    categorie: "Installation",
    couleur: "#4DB8FF",
    materiel: "Volet automatique hors-sol",
    nom: "Volet automatique hors-sol",
    taches: [
      { label: "Assembler les lames du volet" },
      { label: "Fixer l'enrouleur entre les poteaux" },
      { label: "Positionner l'enrouleur et les poteaux sans fixer" },
      { label: "Vérifier l'horizontalité avec un niveau" },
      { label: "Recouper margelles" },
      { label: "Fixer les poteaux au sol" },
      { label: "Tranchées pour câble électrique" },
      { label: "Accrocher le volet à l'enrouleur" },
      { label: "Ouvrir le volet pour vérification" },
      { label: "Régler la fin de course du volet" },
      { label: "Connecter le boîtier de commande" },
      { label: "Installer coffret électrique au local technique" },
    ],
  },
  {
    categorie: "Installation",
    couleur: "#4DB8FF",
    materiel: "Accessoires (échelle, jets, etc.)",
    nom: "Accessoires",
    taches: [
      { label: "Installation des accessoires divers" },
      { label: "Test, vérification et ajustements" },
    ],
  },
  {
    categorie: "Installation",
    couleur: "#4DB8FF",
    materiel:
      "Traitement automatique (Électrolyseur, Lampe UV, Ozonateur, Régulateur pH, Régulateur Redox)",
    nom: "Traitement automatique",
    taches: [
      { label: "Installation et fixation de l'équipement" },
      { label: "Raccordement hydraulique et électrique" },
      { label: "Configuration et réglages initiaux" },
      { label: "Test de fonctionnement et formation à l'utilisation" },
    ],
  },
  {
    categorie: "Installation",
    couleur: "#4DB8FF",
    materiel: "Traitement manuel (Chlorinateur, Brominateur)",
    nom: "Traitement manuel",
    taches: [
      { label: "Installation et fixation de l'équipement" },
      { label: "Raccordement hydraulique" },
      { label: "Configuration et réglages initiaux" },
      { label: "Test de fonctionnement et formation à l'utilisation" },
    ],
  },
  {
    categorie: "Installation",
    couleur: "#4DB8FF",
    materiel: "Coque polyester",
    nom: "Installation piscine coque",
    taches: [
      { label: "Préparation du terrain" },
      { label: "Mise en place du radier" },
      { label: "Mise en place de la coque" },
      { label: "Raccordement hydraulique et électrique" },
      { label: "Remblaiement" },
      { label: "Ceinture béton et pose des margelles" },
      { label: "Mise en eau" },
    ],
  },
  {
    categorie: "Installation",
    couleur: "#4DB8FF",
    materiel: "Béton armé",
    nom: "Piscine béton",
    taches: [
      { label: "Préparation du terrain" },
      { label: "Coffrage et armature" },
      { label: "Coulage du béton" },
      { label: "Raccordement hydraulique et électrique" },
      { label: "Pose du revêtement (carrelage, liner, etc.)" },
      { label: "Mise en eau" },
    ],
  },
  {
    categorie: "Installation",
    couleur: "#4DB8FF",
    materiel: "Polystyrène",
    nom: "Piscine polystyrène",
    taches: [
      { label: "Préparation du terrain" },
      { label: "Terrassement et préparation du sol" },
      { label: "Installation et fixation de la structure" },
      { label: "Raccordement hydraulique et électrique" },
      { label: "Pose du revêtement (carrelage, liner, etc.)" },
      { label: "Remblaiement et finitions" },
      { label: "Mise en eau" },
    ],
  },
  {
    categorie: "Installation",
    couleur: "#4DB8FF",
    materiel: "Bois",
    nom: "Piscine bois",
    taches: [
      { label: "Préparation du terrain" },
      { label: "Assemblage de la structure en bois" },
      { label: "Installation du liner ou du revêtement" },
      { label: "Raccordement hydraulique et électrique" },
      { label: "Mise en eau" },
    ],
  },
  {
    categorie: "Installation",
    couleur: "#4DB8FF",
    materiel: "Bassin",
    nom: "Piscine à débordement",
    taches: [
      { label: "Préparation du terrain et terrassement" },
      { label: "Construction de la structure et du bac tampon" },
      { label: "Raccordement hydraulique et électrique" },
      { label: "Revêtement et finitions" },
      { label: "Mise en eau" },
    ],
  },
  {
    categorie: "Installation",
    couleur: "#4DB8FF",
    materiel: "Bassin",
    nom: "Piscine miroir",
    taches: [
      { label: "Préparation du terrain et terrassement" },
      { label: "Construction de la structure" },
      { label: "Installation du système de miroir d'eau" },
      { label: "Raccordement hydraulique et électrique" },
      { label: "Revêtement et finitions" },
      { label: "Mise en eau" },
    ],
  },
  {
    categorie: "Installation",
    couleur: "#4DB8FF",
    materiel: "Acier",
    nom: "Piscine en acier",
    taches: [
      { label: "Préparation du terrain et terrassement" },
      { label: "Assemblage de la structure en acier" },
      { label: "Installation du liner ou du revêtement" },
      { label: "Raccordement hydraulique et électrique" },
      { label: "Mise en eau" },
    ],
  },
  {
    categorie: "Entretien",
    couleur: "#66CC99",
    materiel: "Bassin",
    nom: "Hivernage passif",
    taches: [
      { label: "Nettoyage de la piscine" },
      { label: "Nettoyage des abords (margelles, plage)" },
      { label: "Contrôle et correction de l'équilibre de l'eau" },
      { label: "Ajout du produit d'hivernage" },
      { label: "Vidange partiel du bassin" },
      { label: "Installation des dispositifs de sécurité" },
      { label: "Installation de la couverture de piscine" },
      { label: "Mise en sécurité du local technique" },
      { label: "Déconnexion du système de chauffage" },
      { label: "Déconnexion des appareils de traitement automatique" },
      { label: "Mise en sécurité des équipements et accessoires" },
      { label: "Nettoyage du local technique" },
    ],
  },
  {
    categorie: "Entretien",
    couleur: "#66CC99",
    materiel: "Bassin",
    nom: "Hivernage actif",
    taches: [
      { label: "Nettoyage de la piscine" },
      { label: "Nettoyage des abords (margelles, plage)" },
      { label: "Contrôle et correction de l'équilibre de l'eau" },
      { label: "Ajout du produit d'hivernage" },
      { label: "Réglage du temps de filtration" },
      { label: "Mise en route du coffret hors-gel" },
      { label: "Installation de la couverture de piscine" },
      { label: "Déconnexion du système de chauffage" },
      { label: "Déconnexion des appareils de traitement automatique" },
      { label: "Mise en sécurité des équipements et accessoires" },
      { label: "Nettoyage du local technique" },
    ],
  },
];

const cloudName = "dqjoneora";

const uploadPreset = "x0wblxzw";

export const CLOUDINARY_CONFIG = {
  cloudName,
  uploadPreset,

  sources: ["local"],
  language: "fr",
  showCompletedButton: true,
  clientAllowedFormats: ["png", "gif", "jpeg", "jpg"],
  text: {
    fr: {
      queue: {
        title: "Téléchargement en cours",
        done: "Terminé",
        retry_failed: "Réessayer",
        uploading: "Téléchargement en cours...",
        error: "Erreur",
        aborted: "Annulé",
        statuses: {
          uploading: "Téléchargement en cours...",
          error: "Erreur",
          uploaded: "Terminé",
          aborted: "Annulé",
        },
      },
      or: "Ou",
      menu: {
        files: "Télécharger une image",
      },
      local: {
        browse: "Parcourir",
        main_title: "Envoi de fichiers",
        dd_title_single:
          "Glisser et déposer une image ici (JPEG, JPG, PNG uniquement)",

        drop_title_single: "Déposez l'image pour l'envoyer",
      },
    },
  },

  showAdvancedOptions: false,
  cropping: false,
  multiple: false,
  defaultSource: "local",
};

export const TINYMCE_API_KEY =
  "a4vypwwziovy4bgela16w9b35uozwvsd4kfbj81d0w0wemzy";
