import {
  MDBRow,
  MDBCol,
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBIcon,
  MDBCollapse,
  MDBContainer,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBNavbarNav,
  MDBNavbarToggler,
  MDBBadge,
  MDBSpinner,
} from "mdb-react-ui-kit";
import "./App.scss";
import { Routes, Route, Link, useNavigate } from "react-router-dom";
import Dashboard from "./Dashboard";
import Techniciens from "./Techniciens";
import ClotureIntervention from "./ClotureIntervention";
import Login from "./Login";
import DetailIntervention from "./DetailIntervention";
import Clients from "./Clients";
import Client from "./Client";
import Intervention from "./Intervention";
import { useEffect, useState } from "react";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import Technicien from "./Technicien";
import Notifications from "./Notifications";
import Template from "./Template";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  const [userUid, setUserUid] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [openBasic, setOpenBasic] = useState(false);
  const navigate = useNavigate();

  const auth = getAuth();
  useEffect(() => {
    if (userUid.length === 0) {
      onAuthStateChanged(auth, (user) => {
        console.log("user : ", user);
        setIsLoading(true);
        if (!user) {
          navigate("/login");
        } else {
          setUserUid(user.uid);
        }
        setIsLoading(false);
      });
    }
  }, [userUid]);

  const logOut = () => {
    signOut(auth).then(() => {
      setUserUid("");
      navigate("/login");
    });
  };

  const navigateTo = (route: string) => {
    navigate(route);
    setOpenBasic(false);
  };

  if (isLoading) {
    return (
      <MDBSpinner className="mx-2" color="info">
        <span className="visually-hidden">Loading...</span>
      </MDBSpinner>
    );
  }

  return (
    <div className="app-container">
      <MDBNavbar
        fixed="top"
        expand="lg"
        light
        bgColor="light"
        className="menu-mobile"
      >
        <MDBContainer fluid>
          <MDBNavbarBrand href="/">
            <img
              src={"https://apps.gemidia.fr/poolcare/assets/logo-poolcare.png"}
              className="App-logo mx-auto d-block"
              alt="logo"
            />
          </MDBNavbarBrand>

          <MDBNavbarToggler
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={() => setOpenBasic(!openBasic)}
          >
            <MDBIcon icon="bars" fas />
          </MDBNavbarToggler>

          <MDBCollapse navbar show={openBasic}>
            <MDBNavbarNav className="mr-auto mb-2 mb-lg-0">
              <MDBNavbarItem>
                <MDBNavbarLink onClick={() => navigateTo("dashboard")}>
                  <MDBIcon icon="calendar-alt" />
                  Interventions
                </MDBNavbarLink>
              </MDBNavbarItem>
              <MDBNavbarItem>
                <MDBNavbarLink onClick={() => navigateTo("techniciens")}>
                  <MDBIcon icon="toolbox" />
                  Techniciens
                </MDBNavbarLink>
              </MDBNavbarItem>
              <MDBNavbarItem>
                <MDBNavbarLink onClick={() => navigateTo("clients")}>
                  <MDBIcon icon="users" />
                  Clients
                </MDBNavbarLink>
              </MDBNavbarItem>
              <MDBNavbarItem>
                <MDBNavbarLink onClick={() => navigateTo("template")}>
                  <MDBIcon icon="file-alt" />
                  Template
                </MDBNavbarLink>
              </MDBNavbarItem>
              <MDBNavbarItem>
                <MDBNavbarLink
                  aria-current="page"
                  onClick={() => navigateTo("notifications")}
                >
                  <MDBIcon icon="bell" />
                  Notifications{" "}
                  <MDBBadge
                    color="danger"
                    light
                    pill
                    className="position-absolute translate-end"
                  >
                    3<span className="visually-hidden">Notifications</span>
                  </MDBBadge>
                </MDBNavbarLink>
              </MDBNavbarItem>
              <MDBNavbarItem>
                <MDBNavbarLink aria-current="page" onClick={() => logOut()}>
                  <MDBIcon icon="power-off" />
                  Déconnexion
                </MDBNavbarLink>
              </MDBNavbarItem>
            </MDBNavbarNav>
          </MDBCollapse>
        </MDBContainer>
      </MDBNavbar>
      <MDBRow>
        <MDBCol size="2" className="sidebar">
          <Link to="/">
            <img
              src={"https://apps.gemidia.fr/poolcare/assets/logo-poolcare.png"}
              className="App-logo mx-auto d-block"
              alt="logo"
            />
          </Link>
          {userUid.length > 0 && (
            <MDBTabs className="flex-column text-center mt-5">
              <Link to="/dashboard">
                <MDBTabsItem>
                  <MDBTabsLink>
                    {" "}
                    <MDBIcon icon="calendar-alt" />
                    Interventions
                  </MDBTabsLink>
                </MDBTabsItem>
              </Link>
              <Link to="/techniciens">
                <MDBTabsItem>
                  <MDBTabsLink>
                    {" "}
                    <MDBIcon icon="toolbox" />
                    Techniciens
                  </MDBTabsLink>{" "}
                </MDBTabsItem>
              </Link>
              <Link to="/clients">
                <MDBTabsItem>
                  <MDBTabsLink>
                    {" "}
                    <MDBIcon icon="users" />
                    Clients
                  </MDBTabsLink>
                </MDBTabsItem>
              </Link>
              <Link to="/template">
                <MDBTabsItem>
                  <MDBTabsLink>
                    {" "}
                    <MDBIcon icon="file-alt" />
                    Template
                  </MDBTabsLink>
                </MDBTabsItem>
              </Link>
              <Link to="/notifications">
                <MDBTabsItem>
                  <MDBTabsLink>
                    {" "}
                    <MDBIcon icon="bell" />
                    Notifications{" "}
                    <MDBBadge
                      color="danger"
                      light
                      pill
                      className="ms-2 badge badge-danger rounded-pill position-absolute translate-end"
                    >
                      3<span className="visually-hidden">Notifications</span>
                    </MDBBadge>
                  </MDBTabsLink>
                </MDBTabsItem>
              </Link>
              <MDBTabsItem onClick={() => logOut()}>
                <MDBTabsLink>
                  {" "}
                  <MDBIcon icon="power-off" />
                  Déconnexion
                </MDBTabsLink>
              </MDBTabsItem>
            </MDBTabs>
          )}
        </MDBCol>

        <MDBCol className="ps-5 pe-5 pt-3 light-grey col-12 col-md-10">
          <Routes>
            <Route index path="/" element={<Dashboard userUid={userUid} />} />

            <Route
              path="/techniciens"
              element={<Techniciens userUid={userUid} />}
            />
            <Route path="/login" element={<Login />} />
            <Route path="/clients" element={<Clients userUid={userUid} />} />
            <Route
              path="/clients/edit/:id?"
              element={<Client userUid={userUid} />}
            />
            <Route path="/template" element={<Template userUid={userUid} />} />
            <Route
              path="/techniciens/edit/:id?"
              element={<Technicien userUid={userUid} />}
            />
            <Route
              path="/interventions/edit/:id?"
              element={<Intervention userUid={userUid} />}
            />
            <Route path="/notifications" element={<Notifications />} />
            <Route
              path="/dashboard"
              element={<Dashboard userUid={userUid} />}
            />
            <Route
              path="/detail-intervention"
              element={<DetailIntervention />}
            />
            <Route
              path="/cloture-intervention"
              element={<ClotureIntervention />}
            />
          </Routes>
        </MDBCol>
      </MDBRow>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}

export default App;
